<template>
  <div id="content" class="V4">
    <div id='filter' @click="filterMassage3($event)"></div>
    <canvas id="mycanvas"></canvas>
    <file-my :file="file" :file_flag="file_flag" :editIF="editIF"
             @upFile="(file,id)=>{formData.set(id,file)}"
             @deleteObjS="V4delObjS()"></file-my>

    <transition-group class="V4trans" name="flip-list" tag="div">
      <transition-group class="V4trans2" :class="objL._id" v-for="(objL, indexL) in mongoArticle" :key="objL._id" name="flip-list" tag="div" >
        <bdi class="bdiL" :key="objL._id" >
          <span class="editL" v-show="!editIF" :style="{background: indexL===0?'#5f9ba8': indexL===1? '#d08b8b':'#b4b070'}">{{objL.nameL}}</span>
          <input class="editL" :style="{background: indexL===0?'#5f9ba8': indexL===1? '#d08b8b':'#b4b070'}"
                 style="background: rgba(255,255,255,0.2);" type="text" v-show="editIF" v-model="objL.nameL">
<!--          <span class="editL" :style="{background: indexL===0?'#5f9ba8': indexL===1? '#d08b8b':'#b4b070'}">{{objL.formL.length}}</span>-->
          <button v-show="editIF" class="butDelL" @click.stop="V4delL($event,mongoArticle,indexL)"> × </button>     <!-- del L -->
        </bdi>
        <p :key=1>
          <button class="butAddM" v-show="editIF" @click.stop="V4addM(objL.formL)"> ＋ </button>   <!-- add M -->
        </p>

        <div class="divM" v-for="(objM, indexM) in objL.formL" :key="objM._id">
          <bdi class="bdiM" :key=99>
            <span class="editM" v-show="!editIF" >{{objM.nameM}}</span>
            <input class="editM" type="text" v-show="editIF" v-model="objM.nameM" style="width: 120px">
<!--            <span class="editM">{{objM.arrM.length}}</span>-->
            <button class="butDelM" v-show="editIF" @click.stop="V4DelM($event,objL.formL, indexM)"> × </button>      <!-- del M -->
          </bdi>
          <transition-group class="V4trans3" name="flip-list" tag="div">
            <div class="divS" v-for="(objS, indexS) in objM.arrM" :key="objS._id" :data-id="objS._id" :data-title="objS.title" :data-attri="objS.attributes">
              <div style="display: inline-block">
                <div v-if="similarity" style="position: absolute;transform: translate(1px,41px);color: darkred;font-weight: bold;background: #e3e3e3;width: 54px;text-align: center"></div>
                <img class="S_img S_img2" :src="objS.imgUrl" :alt="objS._id" draggable="false" :data-id="objS._id" :data-title="objS.title" :data-attri="objS.attributes"
                   @mouseover="Simg_over_M1($event,objS)" @mouseout="Simg_out_M2($event,objS)" @click.stop="Simg_click_M3($event,objS,indexS,indexM,indexL)">
              </div>
                <div class="img_div" v-if="list_if1"> <div v-for="(proper) in objS.proper">{{ proper }}</div> </div>
              <div class="img_div2" v-if="list_if2">
                <div style="  border-bottom: 1px solid #ceceee;  text-align: center;font-size: 1.2rem ">{{objS.title}} ({{objS.year}})</div>
                <div><div v-for="(attribute) in objS.attributes " style="  margin: 2px;  color: blue; display: inline-block"
                          :style="{ border: objS.proper&&objS.proper.indexOf(attribute)!==-1 ? '1px solid red' : '1px solid #ddd'}">{{ attribute }}</div>
                </div>
                <div style="text-align: left;  word-break: keep-all;  font-style: italic;">{{objS.abstract}}</div>
                <div style="color: red">{{objS.pdf.slice(-18)}}</div>
              </div>
            </div>
            <div class="divS" v-if="editIF"  :key=3>
              <img class="S_img" @click="V4addS(objM.arrM)" src="../../public/img/addS.jpg" alt="add" draggable="false"> <!-- add S -->
            </div>
          </transition-group>
          <img src="../../public/img/down.png" alt="HH" draggable="false"  @click="V4HHauto($event)"
               style="width: 27px; height: 27px; position: absolute; right: 8px;bottom: 6px; border: 1px solid #DDD; border-radius: 3px;z-index: 3">
        </div>
      </transition-group>
    </transition-group>

    <div style="line-height: 70px;background: #F5F5F5; border-top: 1px solid #bbb;text-align: center;">
      <a href="http://beian.miit.gov.cn" target="_blank" style="text-decoration: none;font-size: 1.2rem;color: #666">
        鲁ICP备2021000436号-1</a>
    </div>

    <div class="V4bottom" style="position: absolute;width: 100%  ;height: 25px;bottom: 0; pointer-events: none; z-index: 5">
      <div style="position: absolute;right: 10px">
        <input v-show="!editIF" type="password" v-model="inputPass" size="2" style="padding: 3px 0; pointer-events: auto; text-align: center;box-shadow: inset 0 0 6px #ccc" @keyup.enter="V4edit()">
        <button  @click.stop="V4edit()">edit</button>      <!-- addL -->
      </div>

      <div v-show="editIF" class="V1bott" style=" background: rgba(115,180,196,0.3);">
        <button @click="V4addL()"> ＋ </button>
        <button @click="V4updateArticle($event)">save</button>
        <span style="text-align: center; display: inline-block; width: 60%"></span>
      </div>
    </div>

  </div>

</template>

<script>
import fileMy from "./fileMy";
import mdivMy from "./mdivMy";
import $ from 'jquery'

export default {
  name: "contentMy",
  components: {fileMy, mdivMy},
  props: ['size_flag'],
  data() {
    return {
      List: {}, file_flag: 0, file: [], filter_flag: 0,  check: false, large_flag:0,  isa : '', numAll:0, similarity:0,
      timer: '', timer2:'', timer3:'',
      list_if1:1, list_if2:0, but_show:0,
      mongoArticle:[], editIF:0, formData:new FormData(), indexS:-1,indexM:-1,indexL:-1, imgDel:[], pdfDel:[], inputPass:''
    }
  },
  created() {
    //this.List = this.$json_articles
    this.V4getArticle()
  },
  mounted() {
    $('.V4trans button, .V4bottom button').on('mouseover',function (){
      $(this).css({opacity:0.7})
    }).on('mouseout',function (){
      $(this).css({opacity:1})
    })
    this.$root.$on('class_set', (class_set2, input) => {                                                         // clear 排序
      if (class_set2.size === 0 && input.length === 0) {
        for (let item of this.mongoArticle) {
          for(let item2 of item.formL){
            item2.arrM.sort((a,b)=> b.year - a.year)
          }
        }
      }
      for (let item of this.mongoArticle) {                                                                   // 选择 总体 排序
        for(let item2 of item.formL){
          for(let item3 of item2.arrM){
            item3.proper = []
            let proper = item3.attributes.filter((item) => class_set2.has(item)) //求交集
            item3.proper.push(...proper)
            item3.attributes.shift()
            item3.attributes.unshift(proper.length)
          }
          item2.arrM.sort((a,b) => b.attributes[0] - a.attributes[0])
        }
      }
    })
    this.$root.$on('history_file',(sitem) => {  // 接收 history(asideMy3) 的点击传值，出file
      this.file_flag = 1
      this.file = sitem
      this.filter_flag = this.filter_flag !== 1 ? 1 : ''
    })
    /*this.$root.$on('checkbox', (d) => {     // 接收checkbox的传值，check=0 鼠标声音为生效
      if (d) this.check = 0
      if (!d) this.check = false
    })*/
    this.$root.$on('attributeBack',(setAttr)=>{
      this.file.attributes = Array.from(setAttr)
    })
    this.$root.$on('listchange',(d)=>{
      this.list_if2 = d
      this.list_if1 = !d
    })
  },
  updated() {
    $('.V4trans button, .V4bottom button').on('mouseover',function (){
      $(this).css({opacity:0.7})
    }).on('mouseout',function (){
      $(this).css({opacity:1})
    })
  },
  watch: {
    /*check: function (d) {
      if (d === 1) this.playPause()
    },*/
    size_flag: function () {    // 监听浏览器窗口的变换
      /*let canvas_id = document.getElementById('mycanvas')
      canvas_id.width = canvas_id.offsetWidth
      canvas_id.height = canvas_id.offsetHeight //  计算画布的高*/
    },
    filter_flag: function (e) {
      if (e === 1) {
        //$("#main").css({filter: 'blur(10px)', transition: "0.3s"})  效果不错但不可用  会影响z-index
        $('#filter').css({
          background: "rgba(110, 110, 110, 0.7)", transition: "background 0.3s",
          pointerEvents: 'auto'
        })
      }
      if (e === 0) {
        this.$root.$emit('attributes',this.file.attributes,this.editIF, this.file_flag, 0)
        $('#filter').css({
          background: 'rgba(0,0,0,0)', transition: "background 0.3s",
          pointerEvents: 'none'
        })
      }
    }
  },
  methods: {
    /*mdivMassage(e) {
      //e.currentTarget.style.background = "rgba(64, 164, 229, 0.12)"
      if (this.check === 0) this.check = 1
    },
    mdivMassage2(e) {
      //e.currentTarget.style.background = "rgba(64, 164, 229, 0.05)"
      if (this.check === 1) this.check = 0
    },*/

    ////
    Simg_over_M1(e,item){
      e.currentTarget.style.boxShadow = "1px 1px 2px 1.5px #89c5e2"
      if(item.title==='') return
      // 全局标题
      let SS = this.$$getOffset(e)                                                              // 调用函数 获取各位置参数
      let arr = [['1', item.title], SS.e_left, SS.e_top, SS.e_width, SS.e_height]
      this.$root.$emit("uptitle", 1, arr)                                                         // 传值 1 给 uptitle
      if(this.editIF) return;

      {
        // 连线代码
        let canvas_id = document.getElementById('mycanvas')
        let ctx = canvas_id.getContext('2d')
        canvas_id.width = canvas_id.offsetWidth
        canvas_id.height = canvas_id.offsetHeight //  计算画布的高

        let e_dataid = $(e.currentTarget).data('id')                 // 因下面有异步调用 必须提前赋值data('id') 即data-id
        let setAttr = item.attributes.slice(1)
        this.similarity = 1
        this.timer = setTimeout(() => {

          for (let simg of document.getElementsByClassName('S_img2')) {
            let setAttr2 = new Set(simg.getAttribute('data-attri').split(',').slice(1))
            let a = new Set(setAttr.filter(x => setAttr2.has(x))).size   //求交集
            let b = new Set([...setAttr,...setAttr2]).size           //求并集
            let c = (a/b).toFixed(1)
            if(c > 0.3){
              let SS2 = this.$$getOffset(simg)
              ctx.beginPath();
              ctx.strokeStyle = "#005cfa";
              ctx.lineWidth = .35
              ctx.moveTo(SS.e_left + SS.e_width / 2, SS.e_top + SS.e_height / 2)
              ctx.lineTo(SS2.e_left + SS2.e_width / 2, SS2.e_top + SS2.e_height / 2)
              ctx.stroke()
              let ar = [simg.getAttribute('data-id'), simg.getAttribute('data-title')]
              let arr = [ar, SS2.e_left, SS2.e_top, SS2.e_width, SS2.e_height]
              this.$root.$emit("uptitle", 1, arr)  /* 传值 1 给uptitle */
              simg.parentNode.firstChild.innerHTML = c
            }else {
              simg.parentNode.parentNode.style.visibility = 'hidden'
            }
            // setTimeout 设置鼠标悬浮时间 虚化背景
            $("#mycanvas").css({background: "rgba(200,200,200,0.8)", transition: "background 0.5s"})
          }
        }, 1500)
      }
    },
    Simg_out_M2(e){
      this.similarity = 0
      e.currentTarget.style.boxShadow = "1px 1px 5px #c9c3c3"
      {
        clearTimeout(this.timer);                                                                  //  清除 setTimeout
        $('.divS').css({visibility: "visible"})                           // ② visibility:"visible"    filter:"blur(0)"
        $("#mycanvas").css({background: "rgba(255,255,255,0)"})
      }
      this.$root.$emit("uptitle", 0, "")                                                        //  传参 0 给 uptitle
      document.getElementById('mycanvas').width = document.getElementById('mycanvas').width
    },
    Simg_click_M3(e,item,indexS,indexM,indexL){
      {
        this.indexS = indexS
        this.indexM = indexM
        this.indexL = indexL
      }
      this.file_flag = 1
      this.file = this.mongoArticle[this.indexL].formL[this.indexM].arrM[this.indexS]
      this.filter_flag = this.filter_flag !== 1 ? 1 : ''
      //this.$root.$emit('history', item)
      this.$root.$emit('attributes',this.file.attributes,this.editIF, this.file_flag, 0)
    },
  ////

    filterMassage3() {
      this.file_flag = 0
      this.filter_flag = 0
    },

    p_click_M3(e){// 好像未使用
      clearTimeout(this.timer2)
      clearTimeout(this.timer3)
      if(this.large_flag===0){
        $(e.target).parent('.L_div').siblings().css({flex:'0', transition:'flex 0.4s'})
        $(e.target).next('.trans_G').css({flexWrap:'wrap', paddingTop:'23px'})
        $(e.target).next('.trans_G').children('.divS').children('.S_img').css({margin:'3px 0 3px 14px',height:"100px",width:'100px', transition:'all 0.4s'})
        $(e.target).parent('.L_div').siblings().children('.trans_G').children('.divS').children('.S_img').attr('class','S_img')  // 真绕，是吧
        this.large_flag = 1
        this.timer3 = setTimeout(()=>{this.but_show = 1},400)
      }else  if(this.large_flag===1){
        $(e.target).parent('.L_div').siblings().css({flex:'1', transition:'flex 0.4s cubic-bezier(0.55, 0.055, 0.675, 0.19)'})
        $(e.target).next('.trans_G').css({paddingTop:'0'})
        $(e.target).next('.trans_G').children('.divS').children('.S_img')
            .css({margin:'0',height: '8vmin',width:'8vmin', transition:'0.5s cubic-bezier(0.19, 1, 0.22, 1)'})
        $(e.target).parent('.L_div').siblings().children('.trans_G').children('.divS').children('.S_img').attr('class','S_img S_img2') // 就是，一大家子
        this.timer2 = setTimeout(()=>{
          $(e.target).next('.trans_G').children('.divS').children('.S_img').css({transition:'unset'})
          $(e.target).next('.trans_G').css({flexWrap:'nowrap'})
        },400)
        this.large_flag = 0
        this.list_if1 = 1
        this.list_if2 = 0
        this.but_show = 0
      }
    },

    V4edit(){
      if(this.inputPass !== '9999') return
      if(!this.editIF){
        this.editIF = !this.editIF;
      }else {
        this.editIF = !this.editIF;
        this.imgEditIF = false
        this.V4getArticle()
      }
      this.$root.$emit('attributes',this.file.attributes,this.editIF, this.file_flag, 1)
    },
    V4addL(){
      this.mongoArticle.push(this.$$initiAdd('addL'))
      this.V4addM(this.mongoArticle[this.mongoArticle.length-1].formL)
    },
    V4delL(e,mongoArticle,indexL){
      let lenL = $(e.target).parent('.bdiL').parent('.V4trans2').find('.divS').length
      let lenM = $(e.target).parent('.bdiL').parent('.V4trans2').find('.divM').length
      if(lenL-lenM === 0){
        mongoArticle.splice(indexL,1)
      }else {
        alert('请先删除该内容里所有的文章')
      }
    },
    V4addM(form){
      form.push(this.$$initiAdd('addM'))
    },
    V4DelM(e,objL,indexL){
      let lenM = $(e.target).parent('.bdiM').parent('.divM').find('.divS').length
      if(lenM-1 ===0){
        objL.splice(indexL,1)
      }else {
        alert('请先删除该内容里所有文章')
      }
    },
    V4addS(arrM){
      arrM.push(this.$$initiAdd('addAS'))
    },
    V4delObjS(){
      this.file_flag=0;
      this.filter_flag=0;
      let objMarrM = this.mongoArticle[this.indexL].formL[this.indexM].arrM[this.indexS]//this.objMarrM[this.indexS]
      let imgname = objMarrM.imgUrl.slice(objMarrM.imgUrl.lastIndexOf('/')+1)
      let pdfname = objMarrM.pdf.slice(objMarrM.pdf.lastIndexOf('/')+1)
      if(imgname.length===21 && imgname.slice(0,4)==='imgS'){
        this.imgDel.push(imgname)
        this.formData.delete('img' + objMarrM._id)
      }
      if(pdfname.length===21 && pdfname.slice(0,4)==='pdfS'){
        this.pdfDel.push(pdfname)
        this.formData.delete('pdf' + objMarrM._id)
      }
      this.mongoArticle[this.indexL].formL[this.indexM].arrM.splice(this.indexS,1);
    },
    V4HHauto(e){
      let eParent = $(e.target).parent()
      let hh = $(e.target).prev().outerHeight() + 24
      console.log(eParent,hh)
      hh > eParent.outerHeight() ? eParent.css({height:hh}) : eParent.css({height:88})
    },
    V4getArticle(){
      this.$axios.get('editArticle/getArticle')
          .then(res=>{
            if (typeof res.data === 'string') {
              //$('.bdiRES').text(res.data);  return  // 获取失败的返回
            }
            this.mongoArticle = res.data
            if(this.file_flag) this.file = this.mongoArticle[this.indexL].formL[this.indexM].arrM[this.indexS]
            this.$root.$emit('mongoArticle',this.mongoArticle)
            this.numAll = 0
            for (let item of res.data){
              for(let item2 of item.formL){
                this.numAll += item2.arrM.length
              }
            }
            this.$root.$emit('numAll', this.numAll)
            //this.bdiIF = 1
          }).catch(err=>{
        //$('.bdiRES').text(err)
      })
    },
    V4updateArticle(e){
      let span = $(e.target).next('span')
      this.formData.set('arrX', JSON.stringify(this.mongoArticle))
      this.formData.set('time', JSON.stringify((new Date()).getTime()))
      this.formData.set('imgdel', JSON.stringify(this.imgDel))
      this.formData.set('pdfdel', JSON.stringify(this.pdfDel))
      let configs = {headers:{'Content-Type':"multipart/form-data"}}

/*      $.ajax({
        type:'POST',
        url:'editArticle/updateArticle',
        data:this.formData,
        processData:false,
        contentType:false ,
        success:function (data){
          console.log(data)
          if(data.code === 200){
            //console.log(200)
          }
          //else console.log(data.message)
        }
      })*/
      span.css({color:'yellow', fontSize:'1.5rem', fontWeight:'bold'})
      span.text('上传中。。。')
      this.$axios.post('editArticle/updateArticle',this.formData,configs)
          .then(res=>{
            if (typeof res.data === 'string') {
              this.V4TimeOut(span,60000,'red', res.data); return  // 获取失败的返回
            }
            this.mongoArticle = res.data
            this.V4TimeOut(span,2000,'#0800e2', 'Successfully saved .');
            this.formData = new FormData()
            this.imgDel = []
            this.pdfDel = []
            if(this.file_flag) this.file = this.mongoArticle[this.indexL].formL[this.indexM].arrM[this.indexS]
          }).catch(err=>{
        this.V4TimeOut(span,6000,'red',err);
      })
    },
    V4TimeOut(span, time, _color, _text){
      //clearTimeout(this.timerRes)
      span.css({color:_color, fontSize:'1.5rem', fontWeight:'bold'})
      span.text(_text)
      this.timerRes = setTimeout(()=>{span.text('')},time)
    }
  }
}
</script>

<style scoped>
#content::-webkit-scrollbar {width : 15px; height: 10px}
#content::-webkit-scrollbar-track {background-color : white;}
#content::-webkit-scrollbar-thumb {background-color : #dbdedc;}
#content::-webkit-scrollbar-thumb:active {background-color : #99b4d9;}

.V4trans::-webkit-scrollbar {width : 10px; height: 10px;}
.V4trans::-webkit-scrollbar-track {background-color : white;}
.V4trans::-webkit-scrollbar-thumb {background-color : #99b4d9;}
.V4trans::-webkit-scrollbar-thumb:active {background-color : #99b4d9;}

#content {
  z-index : 2; height : 100%; width: 100%; position: relative; overflow: auto;
}
#mycanvas {
  z-index: 1; position: fixed; left: 0; top: 0; width: 100%; height: 100%; pointer-events: none;
}
#filter {
  z-index: 4; position: absolute; pointer-events: none; width: 100%; height: 100%;
}


.V4trans{
  position: relative; overflow : auto; height: 100%; padding-bottom: 28px;
}
.V4trans2{
  width: 100%; min-height: 42px;
}
.V4trans2 .bdiL{
   width: 100%; display: inline-block; text-align: left;
}
.V4trans2 >p{
  width:100%;  position: absolute; text-align: right; pointer-events: none; z-index: 1;
}
.V4trans2 .bdiL .editL{
  padding: 0 12px;
  line-height: 25px;
  display: inline-block;
  border: none;
  font-weight: bold;
  font-size: 1.7rem;
  color: #444;
  border-radius: 4px;
}
.divM{
  height: 88px; position: relative;
  box-shadow: 0 0 1px #aaa; overflow: hidden;
}
.divM .bdiM{
  display: inline-block;
}
.divM .bdiM .editM{
  line-height: 22px;
  display: inline-block;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #ddd;
  border-radius: 0 0 5px 0;
  background: #eeeeee;
  padding: 0 8px;
  font-size: 1.2rem;
  color: #867e46;
  font-weight: bold;
}
.V4trans3{
}
.V4trans3 >.divS{
  display: inline-block;
  margin: 5px 0 0 15px;
  z-index: 2;
  position: relative;
}

.V4bottom button, .V4 .butAddM{
  line-height: 25px;
  width: 50px;
  background: #73b4c4;
  pointer-events: auto;
  margin-left: 5px;
}
.V4 .butDelL{
  height: 100%; width: 32px;
  background: #e58460;
}
.V4 .butDelM{
  line-height: 20px; width: 22px;
  background: #e58460;
}

.S_img{
  border-radius: 3px;
  border: 1px solid #a7dee7;
  box-shadow: 1px 1px 5px #c9c3c3;
  pointer-events: auto;
  height: 55px;
  width: 55px;
  overflow: hidden;
}
.img_div{
  height: 80%;
  display: inline-block;
/*  flex-wrap: wrap;
  align-content: flex-start;
  flex-direction: column;*/
}
.img_div2{
  display: inline-block;
  background: rgba(100,100,150,0.03);
  width: 90%;
  border: 1px solid #d7a863;
  border-radius: 4px;
  font-size: 1.2rem;
  font-family: "Times New Roman","kaiti", sans-serif;
}
.but2 button{
  border: 1px solid #34a147;
  background: #aac7d0;
}


.V4trans >*, .V4trans2 >*, .V4trans3 >*{
  transition: 0.5s;
}
.flip-list-move {
  transition:all 0.7s;
}
.flip-list-enter, .flip-list-leave-to{
  opacity: 0;
}
.flip-list-leave-active {
  position: absolute;
}

.butDelM, .butDelL, .butAddM, .V1bott :first-child button{
  display: inline-block;
  font-size: 1.5rem;
}
</style>
