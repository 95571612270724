<template>
  <div id="header">
    <!--<slot></slot>-->
    <dl class="font">
      <dt>Color Design Browser</dt>
      <dd>Color Computing in Data Visualization: a Survey</dd>
    </dl>
    <ul>
      <li style="font-size: 16px; margin-right: 27px" @mouseover="liMassage($event)" @mouseout="liMassage2($event)" @click="change(1)" :class="[flag === 1?'active':''] ">Overview</li>
      <li style="font-size: 16px" @mouseover="liMassage($event)" @mouseout="liMassage2($event)" @click="change(4)" :class="[flag === 4?'active':''] "> Summary</li>
<!--      <li @mouseover="liMassage($event)" @mouseout="liMassage2($event)" @click="change(2)" :class="[flag === 2?'active':''] "> Upload</li>
      <li @mouseover="liMassage($event)" @mouseout="liMassage2($event)" @click="change(3)" :class="[flag === 3?'active':''] "> 查 询</li>
      <li @mouseover="liMassage($event)" @mouseout="liMassage2($event)" @click="change(5)" :class="[flag === 5?'active':''] "> 测 试</li>-->
    </ul>
  </div>
</template>

<script>
import $ from "jquery"
export default {
  name: "headerMy",
  data() {
    return {
      flag:1
    }
  },
  methods: {
    liMassage(e) {
      $(e.currentTarget).css({ color:'#67bcd5' })
    },
    liMassage2(e) {
      $(e.currentTarget).css({ color:'#666' })
    },
    change(flag) {
      this.flag = flag
      this.$emit('flagh', flag)
    }
  }
}
</script>

<style scoped>
#header {
  height: 100%;
  /*background-color: rgba(110, 110, 110, 0.5);*/
  /*background: linear-gradient(to bottom,rgba(0, 89, 125, 0.9) 0%, rgba(0, 89, 125, 0.6) 100%);*/
  background: rgba(248,248,248,0.97);
  border-radius: 2px;
  border: 1px solid rgba(0,0,0,0.15);
  box-shadow: 0 0 4px #ddd;
  display: flex;
}
dl{
  flex: 1;
}
dl dt, dl dd{
  height: 50%;
  display: flex;
  margin-left: 14px;
  color: #115172;
  /*text-shadow: 1px 1px 2px #11708b;*/
}
dl dt{
  align-items: flex-end;
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 700;
}
dl dd{
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
}
ul {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
}
li {
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  cursor: pointer;
  color: #666;
  font-size: 1.2rem;
}
.active{
  color: #3781db !important;
  border-bottom: 2px solid #0f7690;
  pointer-events: none;
  font-weight: bold;
}
</style>
