<template>
  <div id="getArticle" @click="clear_click_M3()" style="height:100%; border-top: 1px solid #eeeeee">
    <div class="article_fixed" @click.stop="">
      <textarea v-if="index2==='title'||index2==='doi'||index2==='url'||index2==='year'||index2==='abstract'||index2==='publication_info' "
                v-model="article_all[index1][index2]" :placeholder="'e.g. '+index2"></textarea>
      <div v-if="index2==='authors' ">
        <p class="but1"><button @click="dec_click_M3()">dec 1</button><button @click="add_click_M3()" >add 1</button>
          <span style="flex: 1; text-align: center; font-size: 1.6rem">{{index2}}</span></p>
        <div v-for="(arr) in article_all[index1][index2]">
          <p class="index_p">{{arr[0]}}</p>
          <input type="text" v-model="arr[1]" placeholder="e.g. Author">
          <input type="text" v-model="arr[2]" placeholder="e.g. Author's Affilication">
        </div>
      </div>
      <div v-if="index2==='references' ">
        <p class="but1"><button @click="dec_click_M3()">dec 1</button><button @click="add_click_M3()" >add 1</button>
          <span style="flex: 1; text-align: center; font-size: 1.6rem">{{index2}}</span></p>
        <div v-for="(arr) in article_all[index1][index2]" style="width: 24.4%">
          <p class="index_p">{{arr[0]}}</p>
          <input type="text" v-model="arr[1]" placeholder="e.g. reference" style="width: 95%">
        </div>
      </div>
      <div v-if="index2==='attributes' ">
        <form v-for="(values, name) in classes"><fieldset><legend class="leg2">{{name}}</legend>
          <div class="div_attr2" v-for="(item) in values" @click="attr_M3($event)"
               :style="{border: article_all[index1][index2].indexOf(item.name)!==-1?'2px solid red':'2px solid white'}">
            {{item.name}}</div>
        </fieldset></form>
      </div>
      <div v-if="index2==='id' " style="min-height: 100px;border: 1px solid red; justify-content: center; font-size: 1.4rem">
        {{RES}}
      </div>

    </div>
    <div ></div>
    <table id='getTable'>
      <tr>
        <th style="width: 30px"><div>id</div></th>
        <th style="width: 190px"><div>content</div></th>
        <th style="width: 100px"><div>journals</div></th>
        <th style="width: 220px"><div>title</div></th>
        <th style="width: 40px"><div>year</div></th>
        <th style="width: 50px"><div>doi</div></th>
        <th style="width: 50px"><div>url</div></th>
        <th style="width: 55px"><div>image</div></th>
        <th style="width: 55px"><div>pdf</div></th>
        <th style="width: 50px"><div>authors</div></th>
        <th style="width: 55px"><div>attributes</div></th>
        <th style="width: 60px"><div>abstract</div></th>
        <th style="width: 65px"><div>references</div></th>
        <th style="width: 95px"><div>publication_info</div></th>
        <th style="width: 60px; border: none"></th>
        <th style="width: 60px; border: none"></th>
        <th style="width: 115px; border: none"></th>
      </tr>
      <tr><td v-for="(value, name) in obj" >
        <span v-if="name==='id'"  style="pointer-events: none">0</span>
        <select v-if="name==='content'" v-model="obj[name]" style="color: blueviolet;"><option v-for="(content, index) in contents"
             :style="{color: index>=0&&index<=2? '#ce6767': index>=3&&index<=6 ? '#5daeee' : 'green'}">{{content}}</option>
          <option>No</option>
        </select>
        <input v-if="name==='title'" v-model="obj[name]" type="text" style="color: blueviolet">
      </td>
        <td><button class="buttonSave" @click="get_search_M3()">search</button></td>
        <td><button class="buttonDel" @click="get_full_M3()">full</button></td>
        <td style="border: none"></td>
      </tr>

      <tr v-for="(item, index) in article_all">
        <td v-for="(value, name) in item">
          <span v-if="name==='id' " style="pointer-events: none">{{index+1}}</span>
          <select v-if="name==='content'" v-model="item[name]"><option v-for="(content, index) in contents"
                  :style="{color: index>=0&&index<=2? '#ce6767': index>=3&&index<=6 ? '#5daeee' : 'green'}">{{content}}</option></select>
          <select v-if="name==='journals'" v-model="item[name]"><option>Journals</option><option>Conference</option></select>

          <input v-if="name==='title'||name==='year'||name==='doi'||name==='url'" v-model="item[name]" type="text"
                    @click.stop="all_click_Mass($event,index,name)" >

          <label v-if="name==='image' || name==='pdf'" >
            <input type="file"  @change="get_fileChang($event,index,name)">
            <span>{{ value.name || value.slice(10) }}</span></label>


          <span v-if="name==='authors'||name==='references'||name==='attributes' "
                @click.stop="all_click_Mass($event,index,name)">{{name==='attributes'? value.length-1 : value.length}}</span>

          <textarea v-if="name==='abstract' || name==='publication_info'" v-model="item[name]"
                  @click.stop="all_click_Mass($event,index,name)"></textarea>
        </td>
        <td><button class="buttonSave" @click="get_save_M3(item,index)">save</button></td>
        <td><button  class="buttonDel" @dblclick="get_dec_M3(item,index)">clear</button></td>
        <td>{{item.id!=='' ? fun(item.id):''}}</td>
      </tr>
    </table>

  <!-- 添加文章按钮 -->
    <div class="but1">
      <button @click="get_add_M3()"
              style="position: relative; left: 93%; width: 70px; height: 45px; padding: 3px 5px; margin-bottom: 150px">add</button>
    </div>


  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "getArticleMy",
  data(){
    return{
      obj: {}, mongoClass:[], mongoArticle:[],
      contents:['Colormap Design Guidelines', 'Colormap Perception & Cognition', 'Colormap Quantifications','Colormap Generation',
        'Colormap selection', 'Colormap optimization', 'Colormap Transfer', 'Colormap Assignment', 'Multi-scale Colormap'],
      RES:'', article_all: [], index1:'0',index2:'', classes:'',  set_attr: new Set(),
    }
  },
  created() {
    //this.getfiles()
    this.classes = this.$json_classes
    this.obj = (new this.$$initialize()).initi_obj
  },
  mounted() {
    this.$input_hover()
    this.$lab_span_hover()
    this.$button_hover()
    this.$root.$on('mongoClass',(mongoClass)=>{
      this.mongoClass = mongoClass
    })
    this.$root.$on('mongoArticle',(mongoArticle)=>{
      this.mongoArticle = mongoArticle
    })
  },
  updated() {
    this.$input_hover()
    this.$lab_span_hover()
    this.$button_hover()
    this.$attri_hover()
  },
  watch:{
  },
  methods:{
    initi(){},
    addzero(num){  // 日期加零
      if(num<10) num = '0'+num
      return num
    },
    fun(str){  // 日期
      let addo = this.addzero
      let data = new Date(Number(str.slice(1)))
      return data.getFullYear()+'-'+ addo(data.getMonth()+1) +'-'+addo(data.getDate())+' '+addo(data.getHours())+':'+
          addo(data.getMinutes())+':'+addo(data.getSeconds())
    },

    /*getfiles(){      // 从数据库获取信息
      this.$axios.get('/upload/get_files')
          .then((res)=>{
            if (typeof res.data === 'string'){
              this.index2 = 'id';  this.RES = res.data;   return  // 获取失败的返回
            }
            let files = (new this.$$initialize()).initi_files  //  new 构造函数
            for (let item of res.data){
              item.authors = JSON.parse(item.authors)
              item.attributes = JSON.parse(item.attributes)
              item.references = JSON.parse(item.references)
              if(files.hasOwnProperty(item.content)){files[item.content].push(item) }
            }
            this.article_all = res.data
            this.$root.$emit('res_data',res.data)  // 把数据给summaryMy
            for (let name in files) {  // 初始化紧接着排序
              files[name].sort((a,b)=> a.year - b.year)
            }
            for (let ob in this.$json_articles){
              this.$json_articles[ob] = files[ob]
            }
          })
          .catch((err)=>{
            this.index2 = 'id'
            this.RES = '出错了！: '+ err
          })
    },*/

    clear_click_M3(){
      $('#getTable td').css({boxShadow:'none'})
      this.index2 = ''
    },
    all_click_Mass(e,index,name){
      $('#getTable td').css({boxShadow:'none'})
      $(e.target).parent('td').css({boxShadow:'0 0 4px red, inset 0 0 4px red'})
      this.index1 = index
      this.index2 = name
      this.set_attr = new Set(this.article_all[index][name])
    },
    add_click_M3(){
      let obj = this.article_all[this.index1][this.index2]
      obj.push([obj.length+1])
    },
    dec_click_M3(){
      if (this.article_all[this.index1][this.index2].length >0)  {
        this.article_all[this.index1][this.index2].pop()
      }
    },
    attr_M3(e){
      if(e.target.style.border === '2px solid white'){
        e.currentTarget.style.border = '2px solid red'
        this.set_attr.add(e.currentTarget.innerText)
        this.article_all[this.index1][this.index2] = Array.from(this.set_attr)
      }
      else if (e.currentTarget.style.border === '2px solid red'){
        e.currentTarget.style.border = '2px solid white'
        this.set_attr.delete(e.currentTarget.innerText)
        this.article_all[this.index1][this.index2] = Array.from(this.set_attr)
      }
    },
    get_add_M3(){
      this.article_all.push((new this.$$initialize()).initi_obj)
    },
    get_fileChang(e,index,name){
      this.index1 = index
      this.index2 = name
      let arr = e.target.value
      if(arr === '') return
      let ext = (arr.slice(arr.lastIndexOf('.')+1)).toLowerCase()
      switch (name){
        case 'image': {
          if( ext !== 'png' && ext !== 'jpg'){
            alert('照片需为png、jpg格式')
          }else if(e.target.files[0].size/1024 > 1024) {
            alert('照片需小于1M')
          }else{
            this.article_all[index][name]  = e.target.files[0];
          }
          break
        }
        case 'pdf': {
          this.article_all[index][name] = e.target.files[0];
        }
      }
    },
    get_save_M3(obj,index){
      return                                          // return l ha
      if(obj.title==='') {
        alert('Title 不可为空')
        return
      }
      this.$axios.post('/upload/select_title',{title:obj.title, id:obj.id})
          .then((res)=>{
            if (typeof res.data === 'string'){
              this.index2 = 'id';  this.RES = res.data;    // 获取失败的返回
            }
            else if(res.data.length >= 1){
              this.index2 = 'id'
              this.RES = 'title 重复,插入失败'
            }else if(res.data.length === 0){
              //
              let data = obj.id || 'L' + new Date().getTime()
              let formData = new FormData()
              for (let ob in obj) {            // 数据导入 formdata
                if(ob === 'id'){ formData.append('id',data) }
                else if (ob === 'image' || ob === 'pdf') {
                  if (typeof obj[ob] === 'object') { formData.append(data, obj[ob]) }
                  else { formData.append(ob, obj[ob]) }
                }
                else if (ob === 'authors' || ob === 'attributes' || ob === 'references') { formData.append(ob, JSON.stringify(obj[ob])) }
                else { formData.append(ob, obj[ob].trim() )}
              }
              this.$axios.post('/upload/file_upload', formData)
                  .then((res)=>{
                    if (typeof res.data === 'string'){
                      this.index2 = 'id';  this.RES = res.data;   return  // 获取失败的返回
                    }
                    this.index2 = 'id'
                    this.RES = '序号 '+ (index+1) +',  更新成功'
                    for (let ob in obj){  // 更新数据列表
                      if(ob==='authors'||ob==='attributes'||ob==='references'){obj[ob] = JSON.parse(res.data[ob])}
                      else { obj[ob] = res.data[ob]}
                    }
                    // 要更新 json_article2
                  }).catch((err)=>{
                this.index2 = 'id'
                this.RES = '出错了！: '+ err
              })
              //
            }
          }).catch((err)=>{
        this.index2 = 'id'
        this.RES =  '出错了！: '+ err
      })
    },
    get_dec_M3(obj,index){
      let obj_zero = (new this.$$initialize()).initi_obj
      for (let ob in obj){
        if(ob!=='id') obj[ob] = obj_zero[ob]
      }
    },
    get_search_M3(){
      if(this.obj.content===''&& this.obj.title==='') return
      this.$axios.post('/upload/select_content',{content: this.obj.content, title:this.obj.title})
          .then((res)=>{
            if (typeof res.data === 'string'){
              this.index2 = 'id';  this.RES = res.data;   return  // 获取失败的返回
            }
            for (let item of res.data){
              item.authors = JSON.parse(item.authors)
              item.attributes = JSON.parse(item.attributes)
              item.references = JSON.parse(item.references)
            }
            this.article_all = res.data
          }).catch((err)=>{
            this.index2 = 'id'
            this.RES = '出错了！: '+ err
      })
    },
    get_full_M3(){
      this.getfiles()
      this.obj.content = ''
      this.obj.title = ''
    },
  },
}
</script>

<style scoped>
.article_fixed{
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 1215px;
  left: 50%;
  transform: translate(-50%,0);
  border: 1px solid #3b77e2;
  border-radius: 4px 4px 0 0;
}
.article_fixed textarea{
  display: block;
  border: 2px solid #3b77e2;
  width: 99%;
  margin: 0 auto;
  transform: translate(0,-10px);
  height: 150px;
  font-size: 1.7rem;
  color: black;
}
.article_fixed >div{
  background: white;
  width: 99%;
  margin: 0 auto;
  transform: translate(0,-10px);
  min-height: 180px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  border: 2px solid #2b69d5;
  box-shadow: 0 0 3px #2b69d5;
  border-radius: 4px;
  padding: 4px;
}
.article_fixed >div >p{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 4px 0;
}
.article_fixed >div >div{
  width: 49%;
  display: flex;
  justify-content: space-between;
  margin: 3px 3px;
}
.article_fixed div input{
  width: 48.5%;
  height: 30px;
  border: 1px solid #a5c1c6;
  padding: 5px;
  margin-right: 2px;
}
.article_fixed button {
  width         : 60px;
  height        : 30px;
  margin-right  : 10px;
}
.article_fixed .index_p{
  line-height: 30px;
  margin:auto
}
fieldset{
  border-radius: 4px;
  border: 1px solid #222;
  margin : 0 2px;
}
.leg2 {
  text-align : center;
  background : #03667f;
  font-size: 1.3rem;
  padding: 1px 3px;
  border-radius: 2px;
  color: white;
}
.div_attr2 {
  line-height   : 20px;
  padding       : 1px 4px;
  margin        : 2px 2px;
  background    : #97c2e3;
  border-radius : 1px;
  display       : block;
  font-size     : 1.5rem;
  cursor        : pointer;
  text-align: center;
  font-family: "Times New Roman",serif;
}

#getTable{
  margin: 30px auto 150px auto;
  border-collapse: collapse;
  position: relative;
  font-family: "Times New Roman",serif;
  table-layout: fixed;
  width: 50%;
}
tr th{
  line-height: 28px;
}
th span{
  display: block;
  line-height: 30px;
}
th {
  border: 1px solid #000000;
/*  position: sticky; top: 20px;*/
}
th div{
  background: #db9292;
/*  border: 1px solid #f8d0d0;*/
}
td{
  border: 1px solid #999;
  text-align: center;
  padding: 0 2px 2px 0;
}
td *{
  display: block;
  height: 25px;
  width: 100%;
}
td input, td textarea{
  padding: 0 4px;
  border: none ;
  font-size: 1.2rem;
  border-radius: 0;
  box-shadow: none;
}
td span{
  line-height: 25px;
}
td button{
  width: 40px;
  margin: auto;
  height: 22px;
}
td .buttonSave{
  background: #04a6db;
  border: 1px solid #217d9a;
}
td .buttonDel{
  background: #e58460;
  border: 1px solid #a55c41;
}
td select{
}
td input{
}
td textarea{

}
td label{
  display: block;
}

</style>
