<template>
    <transition-group id="transG2" name="flip-list" tag="div" v-if="show">
      <canvas id="mycanvas2" :key="1" class="c222"></canvas>
      <p :key="2">{{ mdiv.content }}</p>
      <button :key="3" @click.stop="flag = 1">视图</button>
      <button :key="4" @click.stop="flag = 2">列表</button>
      <!--视图-->
      <div class="mSdiv" v-if="flag ===1" v-for="(Sitem2) in mdiv.articles"
           :key="Sitem2.id" :data-id="Sitem2.id" :data-title="Sitem2.title"
           @mouseover="sdivMassage1($event,Sitem2 ,1 ,'mSdiv')" @mouseout="sdivMassage2($event,'mSdiv')" @click.stop="sdivMassage3($event,Sitem2)">
        <img class="mImgs" :src=" './img/'+Sitem2.image" :alt="Sitem2.id" draggable="false">
        <div class="sdiv"><div v-for="(proper) in Sitem2.proper">{{proper}}</div></div>
      </div>
      <!--列表-->
      <div class="mSdiv2" v-if="flag ===2" v-for="(Sitem2) in mdiv.articles"
           :key="Sitem2.id" :data-id="Sitem2.id" :data-title="Sitem2.title"
           @mouseover="sdivMassage1($event,Sitem2,0,'mSdiv2')" @mouseout="sdivMassage2($event,'mSdiv2')" @click.stop="sdivMassage3($event,Sitem2)">
        <img class="mImgs2" :src=" './img/' + Sitem2.image" :alt="Sitem2.id" draggable="false">
        <div class="sdiv2">

          <div class="deta-1">{{Sitem2.title}}({{Sitem2.year}})</div>
          <div class="deta-2">{{ Sitem2.authors }}</div>
          <div class="deta-3">
            <div class="deta-3-1" v-for="(attribute) in Sitem2.attributes "
                 :style="{ border: Sitem2.proper&&Sitem2.proper.indexOf(attribute)!==-1 ? '1px solid red' : ''}">{{ attribute }}</div>
          </div>
        </div>
      </div>
    </transition-group>
</template>

<script>
import $ from "jquery"

export default {
  name: "filterMy",
  props: ["mdiv", "mdiv_flag"],
  data() {
    return {
      show: false,
      flag: 1,
      timer :0
    }
  },
  mounted() {
  },
  watch: {
    mdiv_flag: function (p) {
      if (p === 1) {
        clearTimeout(this.timer)
        this.show = p
        this.$nextTick(() => { /*v-if 语句 动画可以syule加nextTick，但需提前定义transformOrigin，故使用next加tiemout  */
          $("#transG2").css({transformOrigin: this.mdiv.scale, opacity: 0, transform: "scale(0.5)"})
        })
        setTimeout(() => {
          $("#transG2").css({transform: "scale(1)", opacity: 1, transition: "0.5s"})
        }, 0)
      }
      if (p === 0) {
        //$("#transG2").css({transform: "scale(0.5)", opacity: 0, transition: "0.5s"})                  // 去掉消失动画
        this.$root.$emit("filter_mdivBack", 0)
        this.show = p
/*        this.timer = setTimeout(() => {   // data里加变量
        },500)*/
      }
    }
  },
  methods: {
    sdivMassage1(e, sitem, flag, msdiv) {
      e.currentTarget.style.boxShadow = "1px 1px 1px 1.5px gold"
      let SS = this.$$getOffset(e)                                           // 直接接收根组件 $$getOffset() 方法
      let arr = [["1",sitem.title], SS.e_left, SS.e_top, SS.e_width , SS.e_height]
      this.$root.$emit("uptitle", flag , arr)
      // 连线代码
      let canvas_id = document.getElementById('mycanvas2')
      canvas_id.width = canvas_id.offsetWidth
      canvas_id.height = canvas_id.offsetHeight
       let ctx = canvas_id.getContext('2d')
       for (let sdiv of document.getElementsByClassName(msdiv)) {
         if (sitem.references.indexOf(sdiv.getAttribute('data-id')) !== -1) {
           let SS2 = this.$$getOffset(sdiv)
           ctx.beginPath();
           ctx.strokeStyle = "#005cfa";
           ctx.lineWidth = .7
           ctx.moveTo(SS.e_offleft + SS.e_width / 2, SS.e_offtop + SS.e_height / 2)
           ctx.lineTo(SS2.e_offleft + SS2.e_width / 2, SS2.e_offtop + SS2.e_height / 2)
           ctx.stroke()
         }
       }

      { // setTimeout 设置鼠标悬浮时间 虚化背景
        let e_dataid = $(e.currentTarget).data('id')                 // 因下面有异步调用 必须提前赋值data('id') 即data-id
        this.timer = setTimeout(() => {
          $("#mycanvas2").css({background: "rgba(200,200,200,0.8)", transition: "all 0.5s"})
          //$('.Mdiv').css({border: "1px dashed lightgrey"})          //$('p').css({opacity: "0"})
          for (let sdiv of document.getElementsByClassName(msdiv)) {
            let data_id = sdiv.getAttribute('data-id')    /* 获取  :data-id */
            if (sitem.references.indexOf(data_id) === -1 && data_id !== e_dataid) {
              sdiv.style.visibility = 'hidden'
            } else {
              let SS2 = this.$$getOffset(sdiv)
              let ar = [sdiv.getAttribute('data-id'),sdiv.getAttribute('data-title')]
              let arr = [ ar,SS2.e_left, SS2.e_top, SS2.e_width, SS2.e_height]
              this.$root.$emit("uptitle", flag, arr)  /* 传值 1 给uptitle */
            }
          }
        }, 1000)
      }
    },
    sdivMassage2(e,sdiv) {
      {
        clearTimeout(this.timer);                                                                  //  清除 setTimeout
        $('.'+sdiv).css({visibility: "visible"})                           // ② visibility:"visible"    filter:"blur(0)"
        $("#mycanvas2").css({background: "rgba(255,255,255,0)"})
        $('p').css({opacity: "1"})
      }
      e.currentTarget.style.boxShadow = "4px 4px 10px #c9c3c3"
      this.$root.$emit("uptitle", 0, '')
      document.getElementById('mycanvas2').width = document.getElementById('mycanvas2').width
    },
    sdivMassage3(e, sitem) {
      this.$emit("mdiv_file", 1, sitem)
      this.$root.$emit('history', sitem)
    },

    /*aMassage1(e) {
      e.currentTarget.style.textDecoration = "underline"
      e.currentTarget.style.color = "blue"
    },
    aMassage2(e) {
      e.currentTarget.style.textDecoration = "none"
      e.currentTarget.style.color = "#428bca"
    }*/
  },
}
</script>

<style scoped>
#transG2 {
  z-index: 5;
  width: 100%; height: 100%; display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  border-radius: 6px;
  border: 3px solid #ff0000;
  overflow: auto;
}
#mycanvas2 {
  z-index: 5; position: fixed; left: 0; top: 0; width: 100%; height: 100%; pointer-events: none;
}
.mSdiv {
  z-index: 6;
  height: 100px;  margin: 18px;
  box-shadow: 4px 4px 10px #c9c3c3;
  border-radius: 10px;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
}
.mImgs {
  width: 100px;  height: 100px;
  border-radius: 10px;  display: block;    border: 1px solid #f3cd80;
}
.sdiv {
  margin-top: 3px;
}
.sdiv div {
  border: 1px solid #f3cd80; font-family: 'kaiti', serif; color: red;
  border-radius: 2px;background-color: #cce9ec; font-size: 12px;
}
p {
  width: 80%;  font-size: 20px;  color: #ff0000;  padding-left: 30%;
}
button{
  width: 10%;
}
/*  */
.mSdiv2 {
  z-index: 6;
  height: 100px; width: 30%;  margin:1% 1.5%;
  box-shadow: 4px 4px 10px #c9c3c3;
  border-radius: 10px;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
}
.mImgs2{
  height: 100%; border-radius: 5px;  display: block;    border: 1px solid #f3cd80;
}
.sdiv2 {
  flex: 1;  display: flex;
  border-radius: 5px;
  border: 1px solid #b3eeee;
  flex-direction: column;
  justify-content: space-between;
  background-color: #e1e5e8;
}
.deta-1 {
  border-bottom: 1px solid #ceceee;
  font-size: 10px;
  text-align: center;
  font-family: "Times New Roman","kaiti", sans-serif;
}
.deta-2 {
  border-bottom: 1px solid #ceceee;
  font-size: 8px;
  font-family: "kaiti" ,serif;
}
.deta-3 {
  border-bottom: 1px solid #ceceee;
  display: flex;
  flex-wrap: wrap;
}
.deta-3-1 {
  padding: 1px 1px;
  border-radius: 2px;
  margin: 0 2px;
  font-size: 1rem;
  font-family: "kaiti", serif;
  border-right: 1px solid #ceceee;
  text-align: center;
}
/*.deta-5 {
  -webkit-text-size-adjust:none;
  font-size: 1rem;
  font-family: "kaiti", serif;
}
a {
  text-decoration: none;
  color: #428bca;
}*/
.flip-list-move { transition: transform 1s;}
</style>
