<template>
  <transition name="fade">
  <div id="file_id" v-show="file_flag" class="V5" style="word-break: break-word">
    <div class="file">

      <div style="margin-right: 12px; width: 200px;">
        <img :src=" file.imgUrl && (file.imgUrl+'?t='+ (new Date()).getTime())" :alt="file._id" draggable="false"
           style=" width: 200px; height: 200px; border: 1px solid lightgrey;display: block">
<!--        <div class="type">
          <dt>Type:</dt>
          <dd v-show="!editIF">{{file.type}}</dd>
          <dl v-show="editIF">
            <select v-model="file.type"><option></option><option>Journals</option><option>Conference</option></select>
          </dl>
        </div>-->

        <div class="imgUrl" v-show="editIF">
          <dt>Img:</dt>
          <dl>
            <label><input type="file" @change="V4upImg($event,file)">
              <span style="vertical-align: middle; padding: 4px">{{file.imgUrl && (file.imgUrl.search('http') >-1 ?
                  'Local preview': file.imgUrl.search('down')>-1 ? 'Default Image': file.imgUrl.slice(-18))}}</span></label>
          </dl>
        </div>

<!--

        <div class="pdf">
          <dt>Pdf:</dt>
          <dd v-show="!editIF">
            <a :href="file.pdf" target="_blank" @mouseover="aMassage1($event)"
               @mouseout="aMassage2($event)">{{ file.pdf && file.pdf.slice(-18) }}</a></dd>
          <dl v-show="editIF">
            <label style="display: inline-block; margin-top: 5px"><input type="file" @change="V4upPdf($event,file)">
              <span style="vertical-align: middle; padding: 4px">{{ file.pdf && file.pdf !==''? file.pdf.slice(-18) : 'no pdf' }}</span></label>
          </dl>
        </div>
-->
<!--

        <div class="url">
          <dt>Url:</dt>
          <dd v-show="!editIF"><a :href="file.url" target="_blank" @mouseover="aMassage1($event)"
               @mouseout="aMassage2($event)">{{ file.url }}</a> </dd>
          <dl v-show="editIF">
            <input type="text" v-model="file.url">
          </dl>
        </div>
-->

        <div class="doi" style="margin-top: 20px">
          <dt>Doi:</dt>
          <dd v-show="!editIF"><span>{{ file.doi }}</span> </dd>
          <dl v-show="editIF">
            <input type="text" v-model="file.doi">
          </dl>
        </div>
      </div>


      <div class="font_div" style="flex: 1">
        <div class="title">
          <h1 v-show="!editIF">{{ file.title }} ({{file.year}})</h1><!--({{file.journals}})-->
          <div v-show="editIF">
            <label style="margin-right: 20px;">Title: <input type="text" v-model="file.title" style="width: 65%"></label>
            <label>Year: <input type="text" v-model="file.year" size="1"></label>
          </div>
        </div>

        <div class="authors" style="border:0 solid #ddd;">
          <p>Author (Affilication):</p>
          <div  v-show="!editIF">
            <span v-for="(arr,index) in file.authors">{{arr[0]}} ({{arr[1]}}); </span> </div>
          <div v-show="editIF">
            <label v-for="(arr,index) in file.authors" style="display: block;margin: 5px 0">{{index+1}}
              <input type="text" v-model="arr[0]"> <input type="text" v-model="arr[1]"> </label>
            <div class="V5butDiv but1">
              <button @click="file.authors.pop()">del 1</button>
              <button @click="file.authors.push([])" >add 1</button> </div>
          </div>
        </div>


        <div class="attributes">
          <div v-for="(attribute) in file.attributes "
               :style="{ border: file.proper&&file.proper.indexOf(attribute)!==-1 ? '1px solid red' : ''}">{{ attribute }}
          </div>
        </div>

        <div class="abstract">
          <p>Abstract:</p>
          <div v-show="!editIF">{{ file.abstract }}</div>
          <div v-show="editIF">
            <textarea v-model="file.abstract"></textarea>
          </div>
        </div>

        <div class="publication_info">
          <p>Publication Info:</p>
          <div v-show="!editIF">
            <i>{{ file.publication_info }}</i>
          </div>
          <div v-show="editIF">
            <textarea v-model="file.publication_info">
          </textarea>
          </div>
        </div>

        <div class="references" style="border: 0 solid #ddd; padding-bottom: 50px">
          <div v-show="!editIF">
            <a v-for="(reference,index) in file.references" @mouseover="aMassage1($event)"
               @mouseout="aMassage2($event)">{{index+1}}、{{ reference }}<br/></a>
          </div>
          <div v-show="editIF">
            <p>references：</p>
            <label v-for="(reference,index) in file.references" style="display: block; margin: 5px 0">{{index+1}}
              <input type="text" v-model="file.references[index]"><br/></label>
            <div class="V5butDiv but1" >
              <button @click="file.references.pop()">del 1</button>
              <button @click="file.references.push('')" >add 1</button></div>
          </div>
        </div>
      </div>

    </div>

    <button v-if="editIF" class="buttonDel" @click.stop="$emit('deleteObjS')"
            style="background: #e58460;padding: 4px;position: absolute;left: 20px; bottom: 20px ">delete</button>
    <button v-if="editIF" class="buttonDel" @click.stop="V5delS1($event)"
            style="background: #e58460;padding: 4px;position: absolute;left: 20px; bottom: 20px;width: 50px;line-height: 17px">del</button>
  </div>
  </transition>
</template>

<script>
import $ from "jquery"

export default {
  name: "fileMy",
  props: ["file_flag", "file",'editIF'],
  data() {
    return {
    }
  },
  mounted() {
    this.$input_focus()
  },
  updated() {
    this.$input_focus()
    this.$button_hover()
  },
  watch: {
  },
  methods: {
    V4upImg(e,objS){
      let val = e.target.value
      if(val === '') return
      let ext = (val.slice(val.lastIndexOf('.'))).toLowerCase()
      if( !ext.match(/.png|.jpg/)){
        alert('照片需为png、jpg格式')
      }else if(e.target.files[0].size/1024 > 500) {
        alert('照片需小于500k')
      }else{
        let file = e.target.files[0]
        //objS.imgUrl = window.URL.createObjectURL(file)  // 上传前的预览
        this.$emit('upFile',file, 'img'+objS._id)
      }
      e.target.value = ''
    },
    V4upPdf(e,objS){
      let val = e.target.value
      if(val === '') return
      let file = e.target.files[0]
      objS.pdf = file.name
      this.$emit('upFile',file, 'pdf'+objS._id)
      e.target.value = ''
    },
    V5delS1(e){
      $(e.target).prev().animate({left:'100px'},200,function (){
        setTimeout(()=>{
          $(e.target).prev().animate({left:'20px'},200)
        },3000)
      })
    },
    V5delS2(){

    },
    aMassage1(e) {
      e.currentTarget.style.textDecoration = "underline"
      e.currentTarget.style.color = "blue"
    },
    aMassage2(e) {
      e.currentTarget.style.textDecoration = "none"
      e.currentTarget.style.color = "#428bca"
    }
  }
}

</script>

<style scoped>
#file_id {
  z-index: 10;
  position: fixed; left: 310px; top: 70px;
  width: 60%; min-width: 700px;
  border-radius: 5px;
  background-color: #e5e5e5;
  padding: 2px;

}
.file {
  max-height: 500px; overflow: auto;
  display: flex;
  background-color: white;
  padding: 18px 10px;
  border-radius: 5px;
}
.file >div:first-child >*{
  margin-bottom: 7px;
}
.font_div >*{
  margin-bottom: 5px;
}
.V5 textarea{
  width: 100%; height: 50px;
  padding: 4px 6px;
}
.V5 input{
  padding: 4px 6px;
}
.title input{
  padding: 4px 6px;
  font-size: 2.2rem;
}
.title h1{
  font-size: 2.5rem;
  line-height: 1.5;
  text-align: left;
}
.authors input{
  width: 48%;
}
.references input{
  width: 96%;
}
.V5butDiv{
  text-align: right;
}
.V5butDiv button{
  padding: 2px 3px;
  font-size: 1.4rem;
  margin-right: 5px;
}
.V5 p{
  text-align: left;
  font-size: 1.2rem;
  font-style: italic;
}

.attributes {
  display: flex;
  margin: 2px 0 20px 0;
  flex-wrap: wrap;
}
.attributes >div {
  padding: 2px 5px;
  border-radius: 4px;
  line-height: 2;
  margin: 3px;
  font-size: 1.2rem;
  border: 1px solid lightgrey;
  text-align: center;
}
a {
  text-decoration: none;
  color: #428bca;
  cursor: pointer;
}
.V5 dt, .V5 dd, .V5 dl{
  display: inline-block;
  font-size: 1.3rem;
}
.V5 dt{
  width: 30px;
  text-align: right;
  margin-right: 5px;
}
.V5 dl input, .V5 dl select{
  width: 160px;
}
.authors >div, .abstract >div, .publication_info >div, .references >div{
  font-size: 1.3rem;
}

.fade-enter-active, .fade-leave-active {
  transition: .4s cubic-bezier(0.19, 1, 0.22, 1);
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}
</style>
