<template>
  <div id="submit">
    <div class="sub_left">
      <form v-for="(item) in this.form" class="all_form"
            style="width: 100%"><fieldset><legend class="leg1">{{item}}</legend>

        <div v-if="item==='Basic Information' " style="width: 100%">
            <dl><dt>Content</dt><dd><select v-model="obj.content">
                <option v-for="(content,index) in contents"
                      :style="{color: index>=0&&index<=2? '#ce6767': index>=3&&index<=6 ? '#5daeee' : 'green'}">{{content}}</option>
                </select></dd></dl>
            <dl><dt>Journals/Conference</dt><dd>
                <select v-model= obj.journals ><option>Journals</option><option>Conference</option></select>
                </dd></dl>
            <dl><dt>Title</dt><dd><input type="text" v-model="obj.title"><span>{{  }}</span></dd></dl>
            <dl><dt>Year</dt><dd><input type="text" v-model="obj.year"><span>{{ }}</span></dd></dl>
            <dl><dt>Doi</dt><dd><input type="text" v-model="obj.doi"><span>{{  }}</span></dd></dl>
        </div>

        <div v-if="item==='Upload' " style="width: 100%">
          <dl><dt>Image</dt><dd> <label for="file1">
            <input type="file" name="image" id="file1" value="nihao" @change="file_change($event)"><span id="text1">upload img</span></label>
            <span>{{obj.image}}</span><span v-if="obj.image==='' "> 请上传小于1M的png/jpg格式 </span></dd></dl>
          <dl><dt>Pdf</dt><dd> <label for="file2">
            <input type="file" name="pdf" id="file2" @change="file_change($event)"><span id="text2">upload pdf</span></label>
            <span>{{obj.pdf}}</span><span v-if="obj.pdf==='' "> 请上传文献 </span></dd></dl>
          <dl><dt>Url</dt><dd><input type="text" placeholder="url、pdf 两选一" v-model="obj.url"></dd></dl>
        </div>

        <div v-if="item==='Authors' || item==='References'"
             style="width: 100%; display: flex; flex-wrap: wrap; align-content: flex-start">
          <div class="but1" style="width:100%; display: flex; flex-direction: row-reverse">
            <button type="button" @click.stop="but_dec_M3(item.toLowerCase())">dec 1</button>
            <button type="button" @click.stop="but_add_M3(item.toLowerCase())">add 1</button>
          </div>
          <div v-if="item==='Authors'" style="width: 100%; display: flex; flex-wrap: wrap">   <!--作者视图-->
            <p class="div_table"><b>Authors</b></p> <p class="div_table"><b>Authors Affilications</b></p>
            <div v-for="(arr) in obj.authors" style="width: 100%; display: flex; flex-wrap: wrap">
              <div class="div_table"><span style="position: relative; left: -30px">{{arr[0]}}</span><input type="text" v-model="arr[1]"></div>
              <div class="div_table"><input type="text" v-model="arr[2]"></div>
            </div>
          </div>
          <div v-if="item==='References' " v-for="(item2) in obj.references" style="margin-bottom: 5px;" > <!--参考 引用视图-->
            <span style="margin-left: 20px; margin-right: 20px">{{item2[0]}}</span> <input type="text" v-model="item2[1]"></div>
        </div>

        <div v-if="item==='Attributes'" class="div_attr" style="width: 100%; display: flex; flex-wrap: wrap; align-items: flex-start">
            <form v-for="(values, name) in attributes"><fieldset><legend class="leg2">{{name}}</legend>
              <div class="div_attr2" v-for="(item) in values" @click="attr_M3($event)"
                   style="border:2px solid rgb(189, 220, 243)">{{item.name}}</div>
            </fieldset></form>
        </div>

        <div v-if="item==='Abstract' " style="width: 100%">
            <textarea cols="70" rows="8" placeholder="两三句话概括" v-model="obj.abstract"
                      style="margin-left: 50px"></textarea>
        </div>

        <div v-if="item==='Publication Info' " style="width: 100%">
            <textarea cols="70" rows="8" placeholder="出版信息" v-model="obj.publication_info"
                      style="margin-left: 50px"></textarea>
        </div>

      </fieldset></form>
    </div>

      <div class="sub_right">
        <div v-show="res_show===1" v-for="(value,name) in obj" style="display: flex; flex-wrap: wrap; line-height: 27px; padding: 1px 5px; width: 100%">
          <div v-if="name==='content'||name==='journals'||name==='title'||name==='year'||name==='doi'||name==='image'||name==='pdf' "
              class="div_font">
            {{name.toUpperCase()+': '}}</div>
          <div v-if="name==='content'||name==='journals'||name==='title'||name==='year'||name==='doi'||name==='image'||name==='pdf'"
              class='div_font2'>
            {{value}}</div>

          <div v-if="name==='authors'" class="div_font3" style="width: 50%">
            Authors</div>
          <div v-if="name==='authors'" class="div_font3" style="width: 50%">
            Authors Affilications</div>
          <div v-for="(author) in value" v-if="name === 'authors'"
               style="width: 100%; display: flex;">
            <div class="div_font4">{{author[1]}}</div> <div class="div_font4">{{author[2]}}</div></div>
          <div v-if="name==='attributes'" style="width: 100%; display: flex; flex-wrap: wrap; margin-bottom: 3px">
            <p class="div_font3">{{name.toUpperCase()+': '}}</p>
            <div v-for="(item) in value.slice(1)" class="div_font4" style="width: 48%">{{item}}</div>
          </div>
          <div v-if="name==='abstract' || name==='publication_info' " style="width: 100%; margin-bottom: 3px">
            <p class="div_font3">{{name.toUpperCase()+': '}}</p>
            <P class="div_font4">{{value}}</P>
          </div>
          <div v-if="name==='references' " style="width: 100%">
            <p class="div_font3">{{name.toUpperCase()+': '}}</p>
            <div v-for='(item) in value' class="div_font4">{{item[1]}}</div>
          </div>
        </div>

        <div v-show="res_show===2" style="width: 100%">   <!-- 提交反馈的信息 -->
          <dl v-for="(value , name) in RES" style="margin-bottom: 8px; font-weight: bold; font-size: 1.4rem">
          <dt style="width: 100px; display: inline-block; text-align:right;">{{name + ' :'}}</dt>
            <dd style="margin-left: 10px; display: inline-block;">{{value}}</dd>
          </dl>
        </div>
      </div>

    <div class="but1 div_fixed"
         style=" height: 50px; background: #a0c8dc; border-radius: 0 0 4px 4px; bottom: 25px; right: 0; border-top: 1px solid white; ">
      <button @click="reset_M3()">Reset</button>
      <button @click="process_M3()">Process</button>
    </div>

    <div v-if="show" class="div_fixed div_fixed2">
      <p>{{p1}}?</p>
      <button @click="confirm_M3()">confirm</button>
      <button @click="cancel_M3()">cancel</button>
    </div>

  </div>
</template>

<script>
import $ from 'jquery'
//import io from 'socket.io'

export default {
  name: 'submitMy',
  data() {
    return {
      RES: {}, res_show: 1, show: false, filter: 0, p1: '',
      contents:['Colormap Design Guidelines', 'Colormap Perception & Cognition', 'Colormap Quantifications','Colormap Generation',
        'Colormap selection', 'Colormap optimization', 'Colormap Transfer', 'Colormap Assignment', 'Multi-scale Colormap'],
      set_attr: new Set([0]),
      form:['Basic Information', 'Upload', 'Authors', 'Attributes', 'Abstract', 'References', 'Publication Info'],
      obj: {},  attributes: [] , up_files: [],
    }
  },
  created() {
    this.attributes = this.$json_classes
    this.obj = (new this.$$initialize().initi_obj)
  },
  mounted() {
    this.$input_focus()
    this.$lab_span_hover()
    this.$button_hover()
    this.$attri_hover()
  },
  updated() {
    this.$input_focus()
    this.$lab_span_hover()
  },
  computed:{
  },
  watch:{
    filter:function (d){
      if(d===1){
        $('.sub_right, .sub_right +.div_fixed, .sub_left').css({filter: "blur(4px)", transition: '0.3s', cursor:'default', pointerEvents:'none'})
      }
      else if(d===0){
        $('.sub_right, .sub_right +.div_fixed, .sub_left').css({filter: "none", transition: '0.3s',cursor:'auto', pointerEvents:'auto'})
      }
    },
    show:function (d){
      if(d){
        setTimeout(() => {
          $('.div_fixed2').css({ opacity:1, transition: "all 0.3s" })
        })
      }
    }
  },
  methods: {
    but_add_M3(item){
      this.obj[item].push([this.obj[item].length+1])
    },
    but_dec_M3(item){
      if (this.obj[item].length >1)  {
        this.obj[item].pop()
      }
    },
    file_change(e){  // 接收上传的文件给 this.upload
      let target = e.target
      let arr = target.value
      if(arr === '')  return false
      let ext = (arr.slice(arr.lastIndexOf('.')+1)).toLowerCase()
      switch (target.name){
        case 'image': {
          if( ext !== 'png' && ext !== 'jpg'){
            alert('照片需为png、jpg格式')
          }else if(target.files[0].size/1024 > 1024) {
            alert('照片需小于1M')
          }else{
            this.up_files[0] = target.files[0];
            this.obj.image = arr.slice(arr.lastIndexOf('\\') + 1)
          }
          break
        }
        case 'pdf': {
          this.up_files[1] = target.files[0];
          this.obj.pdf = arr.slice(arr.lastIndexOf('\\') + 1)
          break
        }
      }
    },
    attr_M3(e){
      if(e.target.style.border === '2px solid rgb(189, 220, 243)'){
        e.currentTarget.style.border = '2px solid red'
        this.set_attr.add(e.currentTarget.innerText)
        this.obj.attributes = Array.from(this.set_attr)
      }
      else if (e.currentTarget.style.border === '2px solid red'){
        e.currentTarget.style.border = '2px solid rgb(189, 220, 243)'
        this.set_attr.delete(e.currentTarget.innerText)
        this.obj.attributes = Array.from(this.set_attr)
      }
    },
    reset_M3(){
      this.filter = 1
      this.show = true
      this.p1 = "Reset"
    },
    process_M3(){
      return           // return bie wang  l
      if (this.obj.title===''){
        alert('Title 不可为空')
      }else {
        this.filter = 1
        this.show = true
        this.p1 = "Process"
      }
    },
    cancel_M3(){
      this.filter = 0
      this.show = false
      this.p1 = ''
    },
    confirm_M3(){
      this.filter = 0
      this.show = false
      if(this.p1 === "Reset"){
        this.res_show = 1
        this.RES = {}
      }
      else if(this.p1 === "Process"){
        this.res_show = 2
        let formData = new FormData();
        this.up_files.map(item => {  // 循环添加 image 和 pdf
          formData.append(this.obj.title.trim(), item);
        });
        for(let ob in this.obj){  // 对obj循环 添加键值对给dataform
          formData.append(ob, JSON.stringify(this.obj[ob]))
        }        //let configs = {headers:{'Content-Type':'multipart/form-data'}}  axios 传文件 值 会自动设置
        this.$axios.post('/upload/file_upload', formData)
            .then((res) => {
              this.RES = res.data
            })
            .catch((err) => {
              this.RES = err
        })
      }
      this.obj = (new this.$$initialize().initi_obj)
      this.set_attr = new Set([0])
      document.getElementsByClassName('all_form')[1].reset();
      this.up_files = []
      $('.div_attr2').css({ border: '2px solid rgb(189, 220, 243)' })
      this.p1 = ''
    },
  }
}
</script>

<style scoped>
#submit {
  width       : 100%;
  padding-top : 10px;
  background  : #eee;
}
.sub_left {
  width         : 840px;
  margin: auto 30% auto auto;
  padding       : 50px 10px 100px 10px;
  background    : #fefefe;
  border-radius : 3px;
  display: flex;
  flex-wrap: wrap;
}
select{
  width: 200px;
  height: 30px;
}
input[type=text]{
  width: 400px;
  height: 25px;
}

.sub_left dl {
  margin-bottom : 10px;
  font-size     : 1.4rem;
}
.sub_left dt {
  float       : left;
  width       : 118px;
  text-align  : right;
  font-weight : bold;
  line-height : 2.8;
}
.sub_left dd {
  margin-left : 140px;
  line-height : 2.8;
  color       : #999;
}
span {
  margin-left : 30px;
  line-height : 2.8;
}
fieldset {
  padding       : 6px 0;
  margin        : 3px 4px;
  border-color  : rgba(255, 255, 255, .4);
  border-radius : 4px;
}
#submit legend {
  padding       : 3px 4px;
  border-radius : 3px;
}
.leg1 {
  background  : #20a8ce;
  color       : white;
  font-size   : 1.7rem;
  font-weight : bold;
}
.leg2 {
  text-align : center;
  background : #62bfd9;
  font-size: 1.4rem;
}
#submit button {
  width         : 60px;
  height        : 30px;
  margin-right  : 10px;
}
label span {
  padding       : 4px 6px;
  line-height: 27px;
}

.div_attr2 {
  line-height   : 20px;
  padding       : 2px 4px;
  margin        : 3px 3px;
  background    : #97c2e3;
  border-radius : 3px;
  display       : inline-block;
  font-size     : 1.4rem;
  cursor        : pointer;
}
#submit .div_table{
  width: 45%;
  text-align : center;
  margin-bottom: 5px;
}
.div_table input{
  width: 300px;
}
.sub_right {
  position      : fixed;
  right         : 0;
  top           : 75px;
  bottom: 25px;
  width         : 320px;
  border-radius : 3px;
  background    : #c1deec;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 70px;
}
.div_font {
  flex: 1;
  font-weight : bold;
  text-align  : right;
}
.div_font2 {
  flex: 3;
  margin-left:8px;
  color: #ec4949;
  font-size: 1.4rem;
  word-break : break-word;
}
.div_font3{
  width: 100%;
  text-align: center;
  font-weight : bold;
}
.div_font4{
  width: 100%;
  border-bottom: 1px dotted #49b0db;
  border-radius: 4px;
  margin: 2px;
  color: #cd6a6a;
  text-align: center;
  font-size: 1.4rem;
  word-break : break-word;
  min-height: 30px
}

.div_fixed {
  width: 320px;
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.div_fixed2{
  height: 150px;
  background: #9dc8e0;
  border-radius: 4px;
  top:40%; left: 50%;
  border: 1px solid #0898e7;
  transform: translate(-50%,-50%);
  opacity: 0;
  box-shadow: 0 0 3px 1px #3d9cd0
}
.div_fixed2 p{
  width: 100%; height: 30px;
  font-size: 1.6rem;
  text-align: center;
  color: #090101;
  font-weight: bold
}
</style>
