import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'

import classes0 from './assets/data/classes0.json'
import classes1 from './assets/data/classes1.json'
import classes2 from './assets/data/classes2.json'
import article1 from "./assets/data/article1.json";
import article2 from "./assets/data/article2.json";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.prototype.$axios = axios
Vue.prototype.$json_classes = classes2
Vue.prototype.$$classes0 = classes0
Vue.prototype.$json_articles = article2

Vue.component('upTitle', () => import("./layout/upTitle"))
Vue.component('checkBox', () => import("./layout/checkBox"))

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.prototype.playPause = () => {   // 定义鼠标声音
  let aud = new Audio('./audio/audio2.wav')
  aud.volume = 0.05
  aud.currentTime = 0;		// 控制audio时间
  let playPromise = aud.play();
  if (playPromise) {
    playPromise.then(() => {   // 音频加载成功
      setTimeout(() => {
      }, 30); // audio.duration 为音频的时长单位为毫秒
    }).catch((e) => {    // 失败后继续回调
      this.playPause();
    });
  }
}






