<template>
  <div id="summaryMy" class="vue11">
    <header></header>
    <transition-group class="summary" name="flip-list" tag="table">
      <th v-for="(item, index) in class1" :key="index"
          @click="item === 'year' ? sortYearMassage() : '';
          item !== 'title'&& item !== 'year' ? sortNameMassage3(item.nameS) : ''">
        <div v-if="item==='title' || item==='year'" >{{item}}</div>
        <img v-else :src=" item.imgUrl " :alt="item.nameS" @mouseover="vue11_thOver($event,item.nameS)" @mouseout="vue11_thOut($event,item.nameS)">
      </th>
      <tr v-for="(tr) in files" :key="tr._id">
        <td v-for="(td,index) in class1.slice(0,2)" :title="tr[td]">
          <div v-if="index===0" style="width:250px; text-align:left ">{{ tr[td] }}</div>
          <div v-if="index===1" style="width:50px; text-align:center ">{{ tr[td] }}</div>
        </td>
        <td v-for="(td2) in class1.slice(2)"
            :style="{backgroundColor: tr.attributes.indexOf(td2.nameS) !==-1 ? '#79a897':''}"></td>
      </tr>
    </transition-group>

    <footer></footer>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "summaryMy",
  props:['voice'],
  data() {
    return {
      class1: ["title", "year"],
      files: [],
      sort: 1,
    }
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    $('.summary tr').hover(function (){
      $(this).css({background: '#eee'})
    },function (){
      $(this).css({background: 'none'})
    })
    this.$root.$on('mongoClass',(d)=>{
      this.class1 =  ["title", "year"]
      for (let item of d){
        for (let item2 of item.formL){
          for(let item3 of item2.arrM){
            this.class1.push(item3)
          }
        }
        for (let ite of item.arrL){
          this.class1.push(ite)
        }
      }
    })

    this.$root.$on('mongoArticle',(d)=>{
      this.files = []
      for (let item of d){
        for(let item2 of item.formL){
          for(let item3 of item2.arrM){
            this.files.push(item3)
          }
        }
      }
    })
  },
  updated() {
  },
  methods: {
    sortYearMassage() {
      if (this.sort === 1) {
        this.files.sort((a, b) => a.year - b.year)
        this.sort = 0
      } else if (this.sort === 0) {
        this.files.sort((a, b) => b.year - a.year)
        this.sort = 1
      }
    },
/*    sortNameMassage2(){
      if (this.sort === 1) {
        this.files.sort((a,b) => a.id.charCodeAt()- b.id.charCodeAt())
        this.sort = 0
      } else if (this.sort === 0) {
        this.files.sort((a,b) => b.id.charCodeAt()- a.id.charCodeAt() )
        this.sort = 1
      }
    },*/
    sortNameMassage3(name){
      if (this.sort === 1) {
        this.files.sort((a,b) => a.attributes.indexOf(name) - b.attributes.indexOf(name))
        this.sort = 0
      } else if (this.sort === 0) {
        this.files.sort((a,b) => b.attributes.indexOf(name) -  a.attributes.indexOf(name) )
        this.sort = 1
      }
    },
    vue11_thOver(e, name){
      let SS = this.$$getOffset(e)
      let arr = [["1", name], SS.e_left,SS.e_top,SS.e_width, SS.e_height]
      this.$root.$emit("uptitle", 1, arr)
    },
    vue11_thOut(e,name){
      this.$root.$emit("uptitle", 0, '')
    }
  }
}
</script>

<style scoped>
header, footer {
  height: 50px;
  border: 1px dotted #79a897;
}
.summary {
  width: 80%;
  margin: auto;
  border-collapse: collapse;
}
th{
  border: 1px solid #999;
  vertical-align: middle;
}
td {
  border: 1px solid #999;
}
td div{
  line-height: 27px;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 3px;
  font-size: 1.3rem;
}
img{
  width: 32px;
  height: 32px;
}
.flip-list-move {
  transition: transform 1s;
}
</style>
