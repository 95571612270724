<template>
  <div id='aside' @scroll.passive="v1_getScroll($event)" class="V1">
    <div style="width: 33px; position:absolute; left: 0; top:0; bottom: 0; background: #5f9ba8"></div>
    <div class="v1Main">
      <mark @click="v1_mark_M3($event,0)" style="position:fixed" >
        <i style="transform: scale(1.3, 1)" class="el-icon-right"></i>
      </mark>
      <div  style="text-align: center;">
        <p style="font-size: 1.5rem;line-height: 30px">Techniques displayed:</p>
        <p style="font-size: 3rem;line-height: 30px;font-weight: bold">{{numAll2}}</p>
      </div>
      <ul class="switch">
        <li :class="show===1? 'liSelected':'' " @click="show=1"><span>Attri</span></li>
        <li :class="show===2? 'liSelected':'' " @click="show=2"><span>Search</span></li>
        <li :class="show===3? 'liSelected':'' " @click="show=3"><span>History</span></li>
      </ul>
      <div id="asideMy" v-show="show===1">
        <button class="tBut" @click="v1ButClear()">clear</button>
        <button class="tBut" @click="v1ButChange($event)">image</button>
        <button class="tBut" @click="v1ButList($event)">picture</button>
        <bdi class="bdiRES" v-show="bdiIF===0" style="display: block;line-height: 200px;text-align: center; color: #2a763f">
          Connecting to the database ...</bdi>
        <transition-group v-show="bdiIF===1" class="v1_tran" name="flip-list" tag="div" >
          <div class="classL" v-for="(objL,index) in mongoClass" :key="objL._id">
            <mark @click="v1_mark_M3($event,index+1)">
              <i style="transform: scale(1.3, 1)" class="el-icon-right"></i>
            </mark>
            <bdi class="L1"><span class="editL" v-if="!editIF">{{objL.nameL}}</span>
              <input class="editL" v-if="editIF" type="text" v-model="objL.nameL" >
              <button type="button" class="editButdel butDecL" v-if="editIF" @click="V1DelL($event,mongoClass,index)"> × </button> <!-- decL -->
            </bdi>
            <p style="color: red; text-align: center"></p>
            <transition-group class="v1_tran2" name="flip-list" tag="div">
              <form v-for="(objM,index) in objL.formL" :key="objM._id"><fieldset>
                <legend><span class="editM" v-if="!editIF">{{objM.nameM}}</span><input class="editM" v-if="editIF" type="text" v-model="objM.nameM" >
                <button type="button" class="editButdel butDecM" v-if="editIF" @click="V1DelM($event,objL.formL,index)"> × </button></legend> <!-- decM -->
                <transition-group class="v1_tran3" name="flip-list" tag="div">
                  <div class="imgAtti" v-for="(objS,index) in objM.arrM"  :key="objS._id" :data-name=objS.nameS
                       style="display: inline-block; margin: 4px; border: 1px solid #ddd;border-radius: 3px;box-shadow: 1px 1px 2px 2px rgb(255, 255, 255);transition: box-shadow 0s"
                       :style="{width:change==='image'? '40px':'auto', height:change==='image'? '40px':'auto'}"
                       @click.stop="v1ImgClick($event,objM.arrM,index)">
                    <img v-show="change==='image' " :src="objS.imgUrl +'?t='+ (new Date()).getTime()" :alt="objS.nameS" draggable="false"
                         style="border-radius: 3px;display: inline-block"
                         @mouseover="v1OverImg($event,objS.nameS)" @mouseout="v1OutImg($event)">
                    <span v-show="change==='text' " style="display: inline-block; padding: 4px; font-size: 1.4rem; cursor: pointer;user-select: none">{{objS.nameS || 'no'}}</span>
                  </div>                 <!-- addS1 -->
                  <img :key=1 class="editimg" v-if="editIF" @click="v1_addS(objM.arrM)" src="../../public/img/addS.jpg" alt="add" draggable="false">

                </transition-group>
              </fieldset></form>
              <div style="text-align: right" :key=9>
                <button class="butAddM" v-if="editIF" @click="v1_addM(objL.formL)"> ＋ </button>     <!-- AddM -->
              </div>
              <transition-group class="v1_tran3" name="flip-list" tag="div" :key=10>
                <div class="imgAtti" v-for="(objM,index) in objL.arrL"  :key="objM._id" :data-name=objM.nameS
                     style="display: inline-block; margin: 3px; height: 40px; width: 40px;  border: 1px solid #ddd;border-radius: 3px;box-shadow: 1px 1px 2px 2px rgb(255, 255, 255);transition: box-shadow 0s"
                     :style="{width:change==='image'? '40px':'auto', height:change==='image'? '40px':'auto'}"
                     @click.stop="v1ImgClick($event,objL.arrL,index)">
                  <img v-show="change==='image' " :src=" objM.imgUrl+'?t='+ (new Date()).getTime() " :alt="objM.nameS" draggable="false"
                       @mouseover="v1OverImg($event,objM.nameS)" @mouseout="v1OutImg($event)">
                  <span v-show="change==='text' " style="display: inline-block; padding: 4px; font-size: 1.4rem; cursor: pointer;user-select: none">{{objM.nameS || 'no'}}</span>
                </div>              <!-- addS2 -->
                <img class="editimg" :key=1 v-if="editIF" @click="v1_addS(objL.arrL)" src="../../public/img/addS.jpg" alt="add" draggable="false">
              </transition-group>
              <div v-if="editIF" :key=2 style="border: 1px solid #f5f5f6">
                <span style="display: inline-block;width: 211px; text-align: center; color: #0f7690"></span>
              </div>
            </transition-group>
          </div>
        </transition-group>
      </div>
      <aside-my2 v-show="show===2"></aside-my2>
      <aside-my3 v-show="show===3"></aside-my3>
    </div>

    <div v-show="show===1" class="V1bottom" style="position: absolute; left: 33px; bottom: 0; width: 275px;height: 25px;pointer-events: none" >
      <div style="position: absolute;right: 0">
        <input v-show="!editIF" type="password" v-model="inputPass" size="2" style="padding: 3px 0; pointer-events: auto; text-align: center; box-shadow: inset 0 0 6px #ccc" @keyup.enter="v1_edit">
        <button @click.stop="v1_edit()">edit</button>
      </div>
      <div v-show="editIF" class="V1bott" style=" background: rgba(115,180,196,0.3);">
        <button v-if="editIF" @click="v1_addL()"> ＋ </button>        <!-- addL -->
        <button  @click="V1updateClass($event)">save</button>
        <span></span>
      </div>
    </div>

    <dialog class="imgFixed" v-show="imgEditIF" @click.stop="">
      <label>EditName: <input type="text" style="padding: 0; line-height: 25px" v-model="imgArr[index] && imgArr[index].nameS"></label><br/>
      <label>UpImg: <input type="file" @change="v1UpImg($event)">
        <span>{{imgArr[index] && (imgArr[index].imgUrl.search('http') >-1 ? 'Local preview': imgArr[index].imgUrl.search('down')>-1 ?
            'Default Image': imgArr[index].imgUrl.slice(11))}}</span></label>
      <dialog></dialog>
      <button class="buttonDel" @click="V1DelS()">delete</button>
    </dialog>
  </div>
</template>

<script>
import asideMy2 from "./asideMy2";
import asideMy3 from "./asideMy3";
import $ from "jquery"

export default {
  name: "asideMy",
  components: {asideMy2, asideMy3},
  data() {
    return {
      mongoClass:[], editIF:false, imgEditIF:false, scrTop:[0], imgArr:[], index: -1, timerRes:'', change:'image', numAll2:0,
      class_set: new Set(), show: 1, formData: new FormData(), imgdel:[], bdiIF: 0, inputPass:'',
      V4emit:{setAttr:new Set(), editArticle: false, fileflag: false, }
    }
  },
  created() {
    this.V1getClass()
  },
  mounted() {
    this.v1_bdiTop()
    this.v1_getScroll()
    window.addEventListener('resize',this.v1_getScroll)
    $(document).click(()=>{
      this.imgEditIF = false
    })
    $('#aside .tBut').hover(function (){
      $(this).css({background: "#22ae90", transition: "0.2s"})
    },function (){
      $(this).css({background: "white", transition: "0.2s"})
    })
    $('#aside .switch li').hover(function (){
      $(this).css({color:'#8b0404'})
    },function (){
      $(this).css({color:'#000'})
    })

    $('.V1bottom button, #asideMy button').on('mouseover',function (){
      $(this).css({opacity:0.7})
    }).on('mouseout',function (){
      $(this).css({opacity:1})
    })

    this.$root.$on('attributes',(attributes,editIF, fileFlag, num)=>{
      this.V4emit.editArticle = editIF
      this.V4emit.fileflag = fileFlag
      let that = this
      if(num){this.v1ButClear()}
      if(editIF){
        this.v1ButClear()
        if(fileFlag){
          this.V4emit.setAttr = new Set(attributes)
          $('.V1 .imgAtti').each(function (){
            if(that.V4emit.setAttr.has($(this).data('name'))){
              $(this).css({boxShadow: "rgb(0, 114, 139) 1px 1px 2px 2px"})
            }
          })
        }
      }
    })

    this.$root.$on('numAll',(numAll)=>{
      this.numAll2 = numAll
    })
  },
  updated() {
    setTimeout(()=>{  // 主要是为了让动画运行完再执行
      this.v1_bdiTop();
      this.v1_getScroll()
    },520)
    this.v1_getScroll()
    this.$button_hover()

    $('.V1bottom button, #asideMy button').on('mouseover',function (){
      $(this).css({opacity:0.7})
    }).on('mouseout',function (){
      $(this).css({opacity:1})
    })
  },
  watch: {},
  computed: {
    /*len: function () {
      this.$root.$emit('class_set', this.class_set, this.input_data)
      return this.input_data.length
    }*/
  },
  methods: {
    v1_bdiTop(){
      let that = this
      $('#aside .L1').each(function (index, ele) {  // 初始 获取每个大类的高度
        that.scrTop[index+1] = $(this).position().top  //- $('#aside').offset().top
      })
    },
    v1_mark_M3(e,index){
      let height = this.scrTop[index] - 20*index
      $('#aside').animate({scrollTop:height},500)
    },
    v1_getScroll(e){
      let $L1 = $('#asideMy .L1')
      let v1MainT = $('#aside').offset().top + 1
      let winT = $(window).height() - 4
      $L1.each(function (index,ele){
        let offTop = 20 *(index+1) + v1MainT   // 20 是mark的高
        let offBot = winT - 20 *($L1.length -index)
        let L1T = $(this).offset().top
        if(L1T < offTop){
          $(this).prev().css({ position:'fixed', top:offTop, background:'#4b8995'})
        }
        else if(L1T > offBot){
          $(this).prev().css({ position:'fixed', top:offBot, background:'#4b8995'})
        }
        else {
          $(this).prev().css({position:'absolute',top:'unset',background:'#ddd'})
        }
      })
    },
    v1_edit(){
      if (this.inputPass !== '9999') return
      if(!this.editIF){
        this.editIF = !this.editIF;
        this.v1ButClear()
      }else {
        this.editIF = !this.editIF;
        this.imgEditIF = false
        this.V1getClass()
      }
    },
    v1_addL(){
      let objL = this.$$initiAdd('addL')
      this.mongoClass.push(objL)
    },
    v1_addM(form){
      let objM = this.$$initiAdd('addM')
      form.push(objM)
    },
    v1_addS(arrL){
      let objS = this.$$initiAdd('addS')
      arrL.push(objS)
    },
    V1DelL(e,mongoClass,index){
      let lenL = $(e.target).parent('.L1').parent('.classL').find('img').length
      let lenM = $(e.target).parent('.L1').parent('.classL').find('form').length
      if(lenL-lenM-1 === 0){
        mongoClass.splice(index,1)
      }else {
        alert('请先删除该内容里所有的属性')
      }
    },
    V1DelM(e,form,index){
      let lenM = $(e.target).parent().parent().parent('form').find('img').length
      if(lenM-1 ===0){
        form.splice(index,1)
      }else {
        alert('请先删除该内容里所有的属性')
      }
    },
    V1DelS(){
      let imgobj = this.imgArr[this.index]
      let name = imgobj.imgUrl.slice(imgobj.imgUrl.lastIndexOf('/')+1)
      if(name.length===18){
        this.imgdel.push(name)
      }
      this.formData.delete(imgobj._id)
      this.imgEditIF = false
      this.imgArr.splice(this.index,1)
    },
    v1OverImg(e, name) {
      let offset = this.$$getOffset(e)
      if(name==='') return
      let arr = [["1", name], offset.e_left, offset.e_top, offset.e_width, offset.e_height]
      this.$root.$emit("uptitle", 1, arr)
    },
    v1OutImg(e) {
      this.$root.$emit("uptitle", 0, '')
    },
    v1ImgClick(e, imgArr,index) {
      if(this.editIF){
        this.imgEditIF = true
        let offset = this.$$getOffset(e)
        let $imgFixed = $('#aside .imgFixed')
        this.imgArr = imgArr
        this.index = index
        this.$nextTick(()=>{$imgFixed.css({left:offset.e_left, top:offset.e_top-$imgFixed.outerHeight()-7})})
        setTimeout(()=>{
          let text = $('#aside .imgFixed input[type=text]')
          let _val = text.val()
          text.val('').focus().val(_val)
        })
        return
      }
      if(this.V4emit.editArticle && !this.V4emit.fileflag) return;

      let boxShadow = $(e.currentTarget).css('boxShadow')
      if (boxShadow === 'rgb(255, 255, 255) 1px 1px 2px 2px') {
        $(e.currentTarget).css({boxShadow: "rgb(0, 114, 139) 1px 1px 2px 2px"})
        if(this.V4emit.editArticle && this.V4emit.fileflag){
          this.V4emit.setAttr.add(imgArr[index].nameS)
          this.$root.$emit('attributeBack',this.V4emit.setAttr)
        }else {
          this.class_set.add(imgArr[index].nameS)  // 是 nameS
          this.$root.$emit('class_set', this.class_set)
        }
      } else if (boxShadow === 'rgb(0, 114, 139) 1px 1px 2px 2px') {
        $(e.currentTarget).css({boxShadow: "rgb(255, 255, 255) 1px 1px 2px 2px"})
        if(this.V4emit.editArticle && this.V4emit.fileflag){
          this.V4emit.setAttr.delete(imgArr[index].nameS)
          this.$root.$emit('attributeBack', this.V4emit.setAttr)
        }else {
          this.class_set.delete(imgArr[index].nameS)  // 是 nameS
          this.$root.$emit('class_set', this.class_set, '')
        }
      }
    },
    v1UpImg(e){
      let arr = e.target.value
      if(arr === '') return
      let ext = (arr.slice(arr.lastIndexOf('.'))).toLowerCase()
      if( !ext.match(/.png|.jpg/)){
        alert('照片需为png、jpg格式')
      }else if(e.target.files[0].size/1024 > 100) {
        alert('照片需小于100k')
      }else{
        let arr = this.imgArr[this.index]
        let file = e.target.files[0]
        //arr.imgUrl = window.URL.createObjectURL(file)  // 上传前的预览
        this.formData.set(arr._id,file)
      }
     e.target.value = ''
    },
    v1ButClear() {
      this.class_set = new Set()
      this.$root.$emit('class_set', this.class_set, '')
      $("#asideMy .imgAtti").css({boxShadow: "rgb(255, 255, 255) 1px 1px 2px 2px"})
    },
    v1ButChange(e){
      let target = e.target
      if ($(target).text()==='image'){
        $(target).text('text')
        this.change = 'text'
      }else if ($(target).text()==='text'){
        $(target).text('image')
        this.change = 'image'
      }
    },
    v1ButList(e){
      let target = e.target
      if ($(target).text()==='picture'){
        $(target).text('list')
        this.$root.$emit('listchange',1)
      }else if ($(target).text()==='list'){
        $(target).text('picture')
        this.$root.$emit('listchange',0)
      }
    },
    V1getClass(){
      this.$axios.get('editClass/getClass')
          .then(res=>{
            if (typeof res.data === 'string') {
              $('#aside .bdiRES').text(res.data);  return  // 获取失败的返回
            }
            this.mongoClass = res.data
            this.bdiIF = 1
            this.$root.$emit('mongoClass',res.data)
          }).catch(err=>{
        $('#aside .bdiRES').text(err)
      })
    },
    V1updateClass(e){
      let span = $(e.target).next('span')
      this.formData.set('arrX', JSON.stringify(this.mongoClass))
      this.formData.set('imgdel', JSON.stringify(this.imgdel))

      span.css({color:'yellow', fontSize:'1.5rem', fontWeight:'bold'})
      span.text('上传中。。。')
      this.$axios.post('editClass/updateClass',this.formData)
          .then(res=>{
            if (typeof res.data === 'string') {
              this.V1TimeOut(span,4000,'red', res.data); return  // 获取失败的返回
            }
            this.mongoClass = res.data
            this.V1TimeOut(span,2000,'#4172ce', 'Successfully saved .');
            this.formData = new FormData()
            this.imgdel = []
          }).catch(err=>{
        this.V1TimeOut(span,4000,'red',err);
      })
    },
    V1TimeOut(span, time, _color, _text){
      //clearTimeout(this.timerRes)
      span.css({color:_color})
      span.text(_text)
      this.timerRes = setTimeout(()=>{span.text('')},time)
    },

  }
}
</script>

<style scoped>
#aside{
  height: 100%;
  width: 100%;
  padding: 1px 10px 100% 33px;
  border-radius: 0 3px 3px 0;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #bbb;
  background: white;
}
.v1Main{
  position: relative;
}
#aside .switch{
  margin: 10px 7px;
  display: flex;
  border-bottom: 1px solid #aaa;
  justify-content: center;
}
#aside .switch li{
  padding: 3px 0;
  text-align: center;
  width: 65px;
  transform: translate(0, 2px);
  font-size: 1.3rem;
  cursor: pointer;
}
#aside .switch .liSelected {
  border-radius: 3px 3px 0 0 !important;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  border-color: #777 #aaa #f9f9f9 #aaa;
  color: #8b0404 !important;
  font-weight: bold;
}
#aside .tBut {
  background: white;
  border: 1px solid #2a763f;
  width: 45px;
  height: 26px;
  color: black;
  display: inline-block;
  margin-left: 10px;
}
#asideMy .v1_tran >div{
  width: 100%;
  border-width: 2px 2px 2px 0;
  border-style: solid;
  border-color: #5f9ba8;
  border-radius: 2px;
  margin: 14px 0;
}
#asideMy bdi{
  display: inline-block;
}
#asideMy bdi .editL{
  line-height: 22px;
  display: inline-block;
  border-width: 0 1px 1px 0;
  border-style: dotted;
  border-color: #73b4c4;
  border-radius: 0 0 5px 0;
  background: #ececec;
  padding: 1px 10px;
  box-shadow: 0 1px 2px #ececec;
  font-size: 1.5rem;
  color: #867e46;
  font-weight: bold;
}
#aside mark{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 20px;
  text-align: center;
  position: absolute;
  transform: translate(-31px,0);
  background: #4b8995;
  border-radius: 1px;
  cursor: pointer;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #5f9ba8;
  transition: 0s;
}
#asideMy .v1_tran2 form{
  width: 97%;
  margin: 10px 0 10px 9px;
}
#asideMy >div form fieldset {
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #d0c77a;
  border-radius: 2px;
  padding: 5px 3px 9px 8px;
}
#asideMy >div form fieldset legend {
  font-size: 1.4rem;
  text-align: right;
  border: 1px solid #d0c77a;
  border-radius: 2px;
  padding-right: 1px;
}
.v1_tran legend .editM{
  display: inline-block;
  line-height: 24px;
  padding: 0 4px;
  background: #f5f5f6;
  color: #b8a838;
  font-weight: bold;
  border: none;
  box-shadow: none;
}
.v1_tran2 >.v1_tran3{
  padding: 6px 8px 8px 17px;
}
#asideMy img{
  width: 40px;
  height: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.5s, opacity 0s;
}
.editButdel{
  border: 1px solid #e39172;
  background: #e58460;
  font-size: 1.2rem;
}

.butDecL{
  width: 22px;
  height: 22px;
}
.butDecM{
  /*transform: translate(175px,-19px);*/
  width: 22px;
  height: 22px;
}

#aside .imgFixed {
  display: inline-block;
  position: fixed;
  z-index: 199;
  border: 1px solid #8aa6ec;
  background: #1f9dbb;
  border-radius: 3px;
  padding: 0 3px;
}
#aside .imgFixed label{
  display: inline-block;
  line-height: 35px;
}
#aside .imgFixed label span{
  line-height: 20px;
  vertical-align: middle;
  width: 110px;
  margin-left: 15px;
}
#aside .imgFixed >dialog{
  display: inline-block;
  border: none;
  position: absolute;
  background: #1f9dbb;
  width: 10px;
  height: 10px;
  transform:translate(15px,28px) rotate(45deg);
}

#aside .buttonDel{
  background: #e58460;
  margin-left: 18px;
  padding: 4px;
}
.V1bottom button, .V1 .butAddM{
  line-height: 25px;
  width: 50px;
  background: #73b4c4;
  pointer-events: auto;

}

.v1_tran >*, .v1_trans2 >*, .v1_tran3>*{
  transition: all 0.5s;
}
.flip-list-move {
  transition: all 0.5s;
}
.flip-list-enter, .flip-list-leave-to {
  opacity: 0;
}
.flip-list-leave-active {
  position: absolute;
}

.editButdel ,.butAddM ,.V1bott :first-child button{
  display: inline-block;
  font-size: 1.5rem;
}
</style>
