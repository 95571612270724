<template>
  <transition-group class='aside3' tag="div" name="flip-list" style="">
    <div class="history" v-for="(item,index) in arr" :key="item._id"
         @mouseover="hisMass1($event)" @mouseout="hisMass2($event)" @click="hisMass3($event,item)">
      {{ index+1 }}: {{ item.title }}
    </div>
  </transition-group>
</template>

<script>
export default {
  name: "asideMy3",
  data() {
    return {
      arr: []
    }
  },
  mounted() {
    this.$root.$on('history', (val) => {
      if (this.arr.indexOf(val) !== -1) {
        this.arr.splice(this.arr.indexOf(val), 1)
        this.arr.unshift(val)
      } else {
        this.arr.unshift(val)
      }
    })
  },
  methods: {
    hisMass1(e) {
      e.currentTarget.style.background = '#fff1dc'
    },
    hisMass2(e) {
      e.currentTarget.style.background = 'white'
    },
    hisMass3(e, sitem) {
      this.$root.$emit('history_file', sitem)
    }
  }
}
</script>

<style scoped>
.aside3 {
  width: 100%;
  height: 70%;
  border: 2px solid #078ab1;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  color: #37b1f1;
}
.history {
  width: 100%;
  font-size: 1.3rem;
  margin: 3px 0;
  border: 1px solid #fff1dc;
  cursor: pointer;
}

.flip-list-move { transition: transform 0.5s;}
</style>
