<template>
  <div id="footer">
    <p>这是一首简单的小情歌</p>
  </div>
</template>

<script>
export default {
  name: "footerMy",
  data() {
    return{

    }
  }
}
</script>

<style scoped>
#footer{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#footer p{
  color: #18c0ea;
}
</style>
