import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {keepAlive: true},
  },
/*  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/!* webpackChunkName: "about" *!/ "../views/About.vue")
  }*/
];

const router = new VueRouter({
  mode:"history",
  routes
});

export default router;
