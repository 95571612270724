<template>
  <div id="mytext" >
    <button @click="mass">dianji</button>
    <transition-group  name="list-name" tag="div"
                       style=" height: 500px; width: 700px;border: 2px solid black;display: flex; position: relative">
      <div class="ssss" v-for="(item,index) in arr" :key="item" style="border: 1px solid red; margin-left: 10px">
        {{item}}
      </div>

    </transition-group>


  </div>
</template>

<script>

export default {
  name: "my_text1",
  data() {
    return {
      obj:{a:'222',b:'333'}, str:'111',
      arr:[1,2,3,4,5]
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    mass(){
      /*this.obj.name=  []
      this.obj.name.push('888')
      this.str = this.obj*/
      this.arr.push(this.arr.length+1)
    }
  }
}
</script>

<style scoped>
.ssss{
  flex: 1;
  transition: all 1s;
  height: 70px;
}
/*.list-name-move {
  transition: transform 1s;
}*/
.list-name-enter, .list-name-leave-to {
  flex: 0;
}
.list-name-leave-active {
  position: absolute;
}
</style>

