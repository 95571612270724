<template>
  <div class="aside2 v2">
<!--    <div class="asideTop">
      <input type="text" v-model="input_data" @keydown="v2Down($event)" size="4" >
    </div>
    <span style="text-align: left">{{len}}</span>
    <div class='check check-1'><check-box :arr="['silent','voiced','voice']"></check-box></div>
    <div class='check check-2'><check-box :arr="['111','222','check']"></check-box></div>-->
  </div>
</template>

<script>
import $ from "jquery"
export default {
  name: "asideMy2",
  data(){return{
    input_data:'',
  }},
  computed:{
    len:function (){

    }
  },
  methods:{
    v2Down(e){
      let add = 0
      let data = $(e.target).val()
      for (let i = 0; i < data.length; i++) {
        if(data[i].charCodeAt()>10000) add +=2
        if(data[i].charCodeAt()<10000) add +=1
      }
      $(e.target).attr('size',add>4?add:4)
    }
  }
}
</script>

<style scoped>
.aside2{
  border: 1px solid #078ab1;
}
.asideTop {
  height: 120px;
  border-bottom: 1px solid #227b3c;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
}

.asideTop input {
  border: 1px solid #2f8447 !important;
  height: 40px;
  /*width: 100%;*/
  color: #2ea139;
  font-size: 1.5rem;
}
.asideTop button {
  background: white;
  border: 1px solid #2a763f;
  width: 45px;
  height: 26px;
  border-radius: 3px;
  color: black;
}
.asideTop span{
  line-height: 26px;
}
.check-1{

}
.check{
  width: 100%;
  /*border: 1px solid #c3dbf8;*/
  margin: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
