<template>
  <div id="index">
    <up-title></up-title>
    <public-all></public-all>
    <initialize-all></initialize-all>
    <div class="header">
      <header-my @flagh="(e)=>{this.flag = e}"></header-my>
    </div>

    <div class="main" v-show="flag === 1">
      <div class="aside">
        <aside-my></aside-my></div>
      <div class="content">
        <content-my :size_flag="size_flag"></content-my></div>
      <!--<div class="footer" style="width:100%; height: 100px; border: 1px solid #81a3e8;">
        <footer-my></footer-my></div>-->
    </div>

    <div class="submit" v-show="flag === 2">
        <submit-my></submit-my>
    </div>
     <div class="getArticle" v-show="flag === 3">
       <get-article-my></get-article-my>
     </div>
     <div class="summary" v-show="flag === 4">
       <summary-my></summary-my>
     </div>
      <div class="text" v-show="flag === 5">
        <my_text1></my_text1>
      </div>

  </div>
</template>

<script>
import headerMy from "../components/headerMy";
import asideMy from "../components/asideMy";
import contentMy from "../components/contentMy";
import submitMy from "../components/submitMy";
import summaryMy from "../components/summaryMy";
import footerMy from "@/components/footerMy";
import getArticleMy from "@/components/getArticleMy";
import My_text1 from "../components/my_text1";
import  $ from "jquery"
import PublicAll from "@/layout/publicAll";
import InitializeAll from "@/layout/initializeAll";

export default {
  name: "index",
  components: {
    InitializeAll,
    PublicAll, headerMy, asideMy, contentMy, submitMy, summaryMy, footerMy, getArticleMy, My_text1,
  },
  data() { return {
      flag: 1, size_flag: true
    }
  },
  mounted() {
    window.addEventListener("resize", ()=>{this.size_flag = !this.size_flag})
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style scoped>
#index{
  min-width: 800px;
  position: relative;
}
.header{
  width:100%;
  height:65px;
  position:absolute;
  top:-1px;
  z-index:10;
}
.main, .submit, .getArticle, .summary, .text{
  height: 100vh;
  padding-top: 65px;
  overflow:auto;
  position: relative;
}
.main{
  display: flex;
}
.aside{
  width: 310px;
  background: #f9f9f9;
}
.content{
  flex: 1;
}
.aside, .content{
  height: 100%;
  position: relative;
}
</style>
