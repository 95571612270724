<template>
  <div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: "initializeAll",
  data(){return{
  }},
  beforeCreate() {
    Vue.prototype.$$getOffset = (e) => {
      if (e.currentTarget || e.target)
        return {
          e_left: e.currentTarget.getBoundingClientRect().left,
          e_top: e.currentTarget.getBoundingClientRect().top,
          e_width: e.currentTarget.offsetWidth,
          e_height: e.currentTarget.offsetHeight,
          e_offleft: e.currentTarget.offsetLeft,
          e_offtop: e.currentTarget.offsetTop,
        }
      else if (e) return {
        e_left: e.getBoundingClientRect().left,
        e_top: e.getBoundingClientRect().top,
        e_width: e.offsetWidth,
        e_height: e.offsetHeight,
        e_offleft: e.offsetLeft,
        e_offtop: e.offsetTop,
      }
    }

    Vue.prototype.$$initialize = function () {
      this.initi_files = {
        "Colormap Design Guidelines": [],
        "Colormap Perception & Cognition": [],
        "Colormap Quantifications": [],
        "Colormap Generation": [],
        "Colormap selection": [],
        "Colormap optimization": [],
        "Colormap Transfer": [],
        "Colormap Assignment": [],
        "Multi-scale Colormap": []
      }
      this.initi_obj = {
        "id": "",
        "content": "",
        "journals": "",
        "title": "",
        "year": "",
        "doi": "",
        "url": "",
        "image": "",
        "pdf": "",
        "authors": [],
        "attributes": [0],
        "abstract": "",
        "references": [],
        "publication_info": ""
      }
    }

    Vue.prototype.$$initiAdd = function (param){   // 选传参方式、构造函数方式好像都可以
      let data = new Date().getTime()
      if(param === 'addL')
        return {"_id":"L" + data, "nameL": "大类别", "formL": [], "arrL": [] }
      else if(param === 'addM')
        return {"_id":"M" + data, "nameM": "中类别", "arrM": [] }
      else if(param === 'addS')
        return {"_id":"S" + data, "nameS": "", "imgUrl":"./img/down.png"}
      else if(param === 'addAS')
        return {
          "_id":"S" + data,
          "content": "",
          "type": "",
          "title": "",
          "year": "",
          "doi": "",
          "url": "",
          "imgUrl": "./img/down.png",
          "pdf": "",
          "authors": [[]],
          "attributes": [0],
          "proper":[],
          "abstract": "",
          "references": [],
          "publication_info": ""
        }
    }
  }
}
</script>

<style scoped>

</style>
