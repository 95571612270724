<template>
<div>

</div>
</template>

<script>
import Vue from 'vue'
import $ from "jquery";

export default {
  name: "publicAll",
  beforeCreate() {
    Vue.prototype.$input_focus =()=>{
      $('#submit input,#submit textarea,#submit select, .V5 input, .V5 textarea')
          .focus(function (){
        $(this).css({boxShadow: "inset 0 1px 1px rgba(0,0,0,0.075), 0 0 2px #6eb9f3", border: "1px solid #6eb9f3" })
      }).blur(function (){
        $(this).css({boxShadow: "inset 0 1px 1px rgba(0,0,0,0.075)", border: "1px solid #ddd"})
      })
    }
    Vue.prototype.$input_hover =()=>{
      $('#getArticle input,#getArticle select,#getArticle textarea, td span')
          .hover(function (){$(this).css({boxShadow:'inset 0 0 3px blue'})}
              ,function (){ $(this).css({boxShadow:'none'})})
    }
    Vue.prototype.$lab_span_hover =()=>{
      $('label span').hover(function (){
        $(this).css({background:'#7fa7e8'})
      },function (){
        $(this).css({background:'#6e91cb'})
      })
    }
    Vue.prototype.$button_hover =()=>{
      $('.but1 button').hover(function (){
        $(this).css({background:'#25bb9b'})
      },function (){
        $(this).css({background:'#22ae90'})
      })

      $('.buttonSave').hover(function (){
        $(this).css({background:'#008ab8'})
      },function (){
        $(this).css({background:'#04a6db'})
      })
      $('.buttonDel').hover(function (){
        $(this).css({background:'#b86a4d'})
      },function (){
        $(this).css({background:'#e58460'})
      })
    }
    Vue.prototype.$attri_hover =()=>{
      $('.div_attr2').hover(function (){
        $(this).css({background: '#68b8f5'})
      },function (){
        $(this).css({background: '#97c2e3'})
      })
    }
  },
  created() {

    }
}
</script>

<style>
* input[type=text] {
  display       : inline-block;
  padding       : 18px 8px;
  border        : 1px solid #ddd;
  border-radius : 4px;
  color         : #34495e;
  box-shadow    : inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-size     : 1.4rem;
  outline-style : none;
  cursor        : text;
}
* input[type=password] {
  display       : inline-block;
  padding       : 18px 8px;
  border        : 1px solid #ddd;
  border-radius : 4px;
  color         : #34495e;
  box-shadow    : inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-size     : 1.4rem;
  outline-style : none;
  cursor        : text;
}
* input[type=file]{
  display: none;
}
* textarea {
  display: inline-block;
  outline-style : none;
  resize        : none;
  padding       : 10px 8px;
  border        : 1px solid #ddd;
  border-radius : 4px;
  font-size     : 1.4rem;
  cursor        : text;
  box-shadow    : inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
* select{
  display: inline-block;
  border: 1px solid #d5d5a9;
  border-radius: 2px;
  outline-style: none;
  cursor: pointer;
}
* label span{
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #6e91cb;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  color: white;
}
button{
  display       : inline-block;
  outline-style : none;
  cursor        : pointer;
  border-radius : 2px;
  border: none;
  background: white;
}
.but1 button{
  border        : 1px solid #22ae90;
  background    : #22ae90;
  color: white;
}

</style>
